import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import { UserModel } from '../models/UserModel';

// import { config } from '../../../config';

const userAdapter = createEntityAdapter({
    //sortComparer: (a, b) => b.date.localeCompare(a.date), //definire criterio di ordinamento quando ci saranno dati reali
})

export const userApi = {
    _model: null,
    _getModel: (thunkAPI) => {
        const token = thunkAPI.getState().identityReducer.token.signature;

        if(userApi._model === null) {
            userApi._model = new UserModel(token);
        } else {
            userApi._model.setToken(token);
        }
        return userApi._model;
    },
    me: createAsyncThunk(
        'user/me', 
        async (_, thunkAPI) => {
            const token = thunkAPI.getState().identityReducer.token.signature;
            const uid = thunkAPI.getState().identityReducer.identity.user_id;

            const userModel = new UserModel(token);

            try {
                return await userModel.getUser(uid);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'user/fetchById', 
        async ({uid}, thunkAPI) => {
            /** @type {UserModel} */
            const userModel = userApi._getModel(thunkAPI);
            try {
                return await userModel.getUser(uid);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchAll: createAsyncThunk(
      'user/fetchAll', 
      async ({page, term, sorting}, thunkAPI) => {
        /** @type {UserModel} */
        const userModel = userApi._getModel(thunkAPI);

        try {
            /** @type {Pager} */
            const pager = await userModel.getUsers(page, term, sorting);
            
            return {
              hasPrevPage: pager.hasPrevPage,
              hasNextPage: pager.hasNextPage,
              entities: pager.getEntities()
            }
        } catch (apiError) {
            return thunkAPI.rejectWithValue(apiError.toJson());
        }
      }
    ),
    create: createAsyncThunk(
        'user/create', 
        async ({firstname, lastname, tagname, email, password, privacy, terms, invitation = false}, thunkAPI) => {
            const userModel = new UserModel();

            try {
                return await userModel.createUser(firstname, lastname, tagname, email, password, privacy, terms, invitation);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'user/update', 
        async ({id, firstname, lastname, tagname, email, phoneCountry, phoneNumber}, thunkAPI) => {
            const token = thunkAPI.getState().identityReducer.token.signature;
            const userModel = new UserModel(token);

            try {
                return await userModel.updateUser(id, firstname, lastname, tagname, email, phoneCountry, phoneNumber);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ), 
    manageUserLock: createAsyncThunk(
        'user/manageUserLock', 
        async (user, thunkAPI) => {
            const token = thunkAPI.getState().identityReducer.token.signature;
            const userModel = new UserModel(token);

            try {
                return user.locked ? await userModel.unlock(user) : await userModel.lock(user);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ), 
    
    uploadAvatar: createAsyncThunk(
        'user/media/create', 
        async ({user, file}, thunkAPI) => {
            const token = thunkAPI.getState().identityReducer.token.signature;
            const userModel = new UserModel(token);

            try {
                const avatar_media = await userModel.createUserMedia(user, file);
                return { ...user, avatar_media: avatar_media};
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ), 
}

const userSlice = createSlice({
    name: "user",
    initialState: userAdapter.getInitialState({
        status: 'idle',
        error: null,
        me: null,
        term: null,
        sorting: {
            first_name: null,
            last_name: null,
            email: null
        },
        showMoreUsers: true,
    }),
    reducers: {
        setTerm(state, action) {
          state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload
        },
        resetUserSliceStatus(state) {
            state.status = 'idle'
        },
        clearUserList(state) {
            userAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [userApi.me.rejected]: (state, action) => {
            state.status = 'user/me/rejected';
            state.error = action.payload;
        },
        [userApi.me.fulfilled]: (state, action) => {
            state.status = 'user/me/succeeded';
            if(state.me === null) {
                state.me = action.payload;
            }
        },
        [userApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'user/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                userAdapter.addMany(state, action.payload.entities)
            } else {
                userAdapter.setAll(state, action.payload.entities)
            }

            state.showMoreUsers = action.payload.hasNextPage;
            state.error = null;
        },
        [userApi.fetchAll.rejected]: (state, action) => {
            state.status = 'user/fetchAll/rejected'
            state.error = action.payload
        },
        [userApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'user/fetchById/succeeded'
            userAdapter.addOne(state, action.payload);
            state.error = null;
        },
        [userApi.fetchById.rejected]: (state, action) => {
            state.status = 'user/fetchById/rejected'
            state.error = action.payload
        },
        [userApi.create.fulfilled]: (state, action) => {
            state.status = 'user/create/succeeded'
            state.error = null;
            // console.log(action.payload);
            userAdapter.addOne(state, action.payload);
        },
        [userApi.create.rejected]: (state, action) => {
            state.status = 'user/create/rejected';
            state.error = action.payload;
        },
        [userApi.update.fulfilled]: (state, action) => {
            state.status = 'user/update/succeeded'
            state.me = action.payload;
            userAdapter.upsertOne(state, action.payload);
        },
        [userApi.update.pending]: (state, action) => {
            state.status = 'user/update/pending'
        },
        [userApi.update.rejected]: (state, action) => {
            state.status = 'user/update/rejected';
            state.error = action.payload;
        },
        [userApi.manageUserLock.fulfilled]: (state, action) => {
            state.status = 'user/manageUserLock/succeeded'
            state.me = action.payload;
            userAdapter.upsertOne(state, action.payload);
        },
        [userApi.manageUserLock.pending]: (state, action) => {
            state.status = 'user/manageUserLock/pending'
        },
        [userApi.manageUserLock.rejected]: (state, action) => {
            state.status = 'user/manageUserLock/rejected';
            state.error = action.payload;
        },
        [userApi.uploadAvatar.fulfilled]: (state, action) => {
            state.status = 'user/media/create/succeeded'
            state.me = action.payload;
            userAdapter.upsertOne(state, action.payload);
        },
        [userApi.uploadAvatar.pending]: (state, action) => {
            state.status = 'user/media/create/pending'
        },
        [userApi.uploadAvatar.rejected]: (state, action) => {
            state.status = 'user/media/create/rejected';
            state.error = action.payload;
        },
    }
});

export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
} = userAdapter.getSelectors((state) => state.userReducer)

export const { setTerm, setSorting, clearUserList, resetUserSliceStatus } = userSlice.actions;

export default userSlice.reducer;
