import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,
} from '@reduxjs/toolkit'
import { SurveyModel } from '../models/SurveyModel';
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';


const responseMediaAdapter = createEntityAdapter()

export const responseMediaApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'response/media/fetchAll', 
        async ({page, surveyId, responseId}, thunkAPI) => {
            try {
                const token = responseMediaApi._getToken(thunkAPI);
                const surveyModel = new SurveyModel(token);

                /** @type {Pager} */
                const pager = await surveyModel.getSurveyResponseMedia(page, surveyId, responseId);
                
                let entities = pager.getEntities().map((entity) => {
                    return { ...entity, entityId: responseId }
                })

                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: entities
                }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const responseMediaSlice = createSlice({
    name: "response/media",
    initialState: responseMediaAdapter.getInitialState({
        status: 'idle',
        error: null,
    }),
    reducers: {
        clearResponseMediaList(state, action) {
            responseMediaAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [responseMediaApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'response/media/fetchAll/succeeded'

            responseMediaAdapter.upsertMany(state, action.payload.entities)

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [responseMediaApi.fetchAll.rejected]: (state, action) => {
            state.status = 'response/media/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectById: selectResponseMediaById,
    selectAll: selectAllResponseMedia,
} = responseMediaAdapter.getSelectors((state) => state.responseMediaReducer)

export const selectAllMediaByResponseId = createSelector(
    (state, _) => selectAllResponseMedia(state),
    (_, responseId) => responseId, 
    (media, responseId) => {
        return media.filter(medium => medium.entityId === responseId);
    }
);

export const { clearResponseMediaList } = responseMediaSlice.actions;

export default responseMediaSlice.reducer;
