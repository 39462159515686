import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class NotificationApiClient extends AdvancedApiClient {

    static entityType = "Notification"

    constructor(token, deviceId, id) {
        super();

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/device/{did}/notification/{id}")
                .setRouteParam("did", deviceId)
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/device/{did}/notification")
                .setRouteParam("did", deviceId);
        }

        this.setEntityType(NotificationApiClient.entityType)
            .appendAuthSchema(new JWTAuth(token));
    }

}