import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class IdentityApiClient extends AdvancedApiClient {

    static entityType = "Identity"

    constructor(deviceId, token) {
        super();

        this.setEntityType(IdentityApiClient.entityType)
            .setRoute("/api/v1/device/{did}/identity");

        this.setRouteParam("did", deviceId);
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}