import { RequestManager } from "./RequestManager";

import { ApiRequest } from "./request/ApiRequest";
import { LHSFilter } from "./request/filter/LHSFilter";

import { ApiError } from "./response/ApiError";
import { Pager } from "./response/Pager";


export class ApiClient extends RequestManager {

    static FilterMode = {
        AND: "AND",
        OR: "OR"
    }

    id = undefined;
    route = undefined;
    entityType = undefined;

    // private headers: Map<string, string> = new Map();
    request = new ApiRequest();


    routeParams = new Map();

    filterMode = ApiClient.FilterMode.AND;


    /**
     * @type {[LHSFilter]}
     */
    filterCriteria = []; //: LHSFilter[] = [];
    // private filterHypermedia?: HyperMediaEntity;
    
    /**
     * @type {[OrderCriteria]}
     */
    orderCriteria = []; // OrderCriteria[];


    setRoute(route) {
        this.route = route;
        return this;
    }

    getRoute() {
        return this.route;
    }

    setEntityType(entityType) {
        this.entityType = entityType;
        return this;
    }

    getEntityType() {
        return this.entityType;
    }

    setRouteParam(key, value) {
        this.routeParams.set(key, value);
        return this;
    }

    getRouteParams() {
        return this.routeParams;
    }

    setLang(lang) {
        this.request.setHeader("Accept-Lang", lang);
        return this;
    }

    setVersion(version) {
        this.request.setHeader("Accept-Version", version);
        return this;
    }

    // addHeader(key: string, value: string): ApiClient<E> {
    //     this.headers.set(key, value);
    //     return this;
    // }

    // public removeHeader(key: string): ApiClient<E> {
    //     if (this.headers.has(key)) {
    //         this.headers.delete(key);
    //     }
    //     return this;
    // }

    // public setHeader(key: string, value: string): ApiClient<E> {
    //     this.headers.set(key, value);
    //     return this;
    // }

    // public getHeader(key: string): string | undefined {
    //     return this.headers.get(key);
    // }

    // public getHeaders(): Map<string, string> {
    //     return this.headers;
    // }

    /**
     * 
     * @param {[OrderCriteria]} criteria 
     * @returns {ApiClient}
     */
    orderBy(criteria) {
        this.orderCriteria = criteria;

        if (this.orderCriteria !== undefined) {
            this.orderCriteria.sort(this.compareOrderCriteria);
            
            const orderParams = [];
            for (const order of this.orderCriteria) {
                orderParams.push(order.toString());
            }

            this.request.setParam("sort", orderParams.join("|"));

            // params.append("sort", orderParams.join("|"));
        }

        return this;
    }

    filterById(id) {
        this.id = id;
        this.setRouteParam("id", id);

        return this;
    }

    filterByString(q) {
        this.request.setParam("q", q);
        return this;
    }

    // public filterByHyperMedia(hypermedia: HyperMediaEntity): ApiClient<E> {
    //     this.filterHypermedia = hypermedia;
    //     return this;
    // }

    /**
     * 
     * @param {[LHSFilter]} criteria 
     * @param {*} mode 
     * @returns {ApiClient}
     */
    filterByLHSFilter(criteria, mode = ApiClient.FilterMode.AND) {
        if(Array.isArray(criteria)) {
            const isValid = criteria.every((element) => {
                return element instanceof LHSFilter;
            });

            if(isValid) {
                this.filterCriteria = criteria;

                for (const filter of this.filterCriteria) {
                    // filter.apply(params);
                    this.request = filter.apply(this.request);
                }
            } else {
                throw new Error(`ERROR: Invalid filter type`);
            }
        } else {
            throw new Error(`ERROR: criteria is not an array of LHSFilter`);
        }

        switch(mode) {
            case ApiClient.FilterMode.AND:
            case ApiClient.FilterMode.OR:
                this.filterMode = mode;

                if (this.filterMode !== undefined) {
                    // params.append("mode", this.filterMode);
                    this.request.setParam("mode", this.filterMode)
                }
                break;
            default:
                throw new Error(`ERROR: Invalid filter mode ${mode}`);

        }

        return this;
    }

    /**
     * 
     * @returns {Promise}
     */
    findOne() {
        return new Promise((resolve, reject) => {
            this._buildRequest(ApiRequest.HTTPMethod.GET);
            this.get(this.request)
                .then(apiResponse => {
                    const apiResponseParsed = this._parse(apiResponse);
                    if (apiResponseParsed.error === undefined) {
                        resolve(apiResponseParsed.entity);
                    } else {
                        reject(apiResponseParsed.error);
                    }
                });
        });
    }

    /**
     * 
     * @returns {Promise}
     */
    findAll() {
        return new Promise((resolve, reject) => {
            this._buildRequest(ApiRequest.HTTPMethod.GET);
            this.get(this.request)
                .then(apiResponse => {
                    const apiResponseParsed = this._parseMultiple(apiResponse);
                    if (apiResponseParsed.error === undefined) {
                        resolve(apiResponseParsed.entities);
                    } else {
                        reject(apiResponseParsed.error);
                    }
                });
        });
    }

    /**
     * 
     * @returns {Promise}
     */
    paginate(from, to, unit = Pager.RangeUnit.items) {
        return new Promise((resolve, reject) => {
            // const that = this;

            this.request.setHeader("Range", `${unit}=${from}-${to}`);
            this._buildRequest(ApiRequest.HTTPMethod.GET);
            this.get(this.request)
                .then(apiResponse => {
                    const apiResponseParsed = this._parseMultiple(apiResponse);
                    if (apiResponseParsed.error === undefined) {
                        const pager = new Pager(this, apiResponse);
                        pager.setEntities(apiResponseParsed.entities);
                        // pager.entities = apiResponseParsed.entities;
                        resolve(pager);
                    } else {
                        reject(apiResponseParsed.error);
                    }
                });
        });

    }

    edit(data) {
        return new Promise((resolve, reject) => {
            this._buildRequest(ApiRequest.HTTPMethod.PATCH, data);
            this.patch(this.request)
                .then(apiResponse => {
                    const apiResponseParsed = this._parse(apiResponse);
                    if (apiResponseParsed.error === undefined) {
                        resolve(apiResponseParsed.entity);
                    } else {
                        reject(apiResponseParsed.error);
                    }
                });
        });
    }

    save(entity, onProgressCallback) {
        this.onProgressCallback = onProgressCallback;

        return new Promise((resolve, reject) => {
            if (entity.id === undefined || entity.id === null) {
                this._buildRequest(ApiRequest.HTTPMethod.POST, entity);
                this.post(this.request)
                    .then(apiResponse => {
                        const apiResponseParsed = this._parse(apiResponse);
                        if (apiResponseParsed.error === undefined) {
                            resolve(apiResponseParsed.entity);
                        } else {
                            reject(apiResponseParsed.error);
                        }
                    });
            } else {
                this._buildRequest(ApiRequest.HTTPMethod.PUT, entity);
                this.put(this.request)
                    .then(apiResponse => {
                        const apiResponseParsed = this._parse(apiResponse);
                        if (apiResponseParsed.error === undefined) {
                            resolve(apiResponseParsed.entity);
                        } else {
                            reject(apiResponseParsed.error);
                        }
                    });
            }
        });
    }

    update(entity, onProgressCallback) {
        this.onProgressCallback = onProgressCallback;

        return new Promise((resolve, reject) => {
            this._buildRequest(ApiRequest.HTTPMethod.PUT, entity);
            this.put(this.request)
                .then(apiResponse => {
                    const apiResponseParsed = this._parse(apiResponse);
                    if (apiResponseParsed.error === undefined) {
                        resolve(apiResponseParsed.entity);
                    } else {
                        reject(apiResponseParsed.error);
                    }
                });
        });
    }

    // public saveMultiPayload(entities: Map<string, Entity> | Entity[] | Entity, onProgressCallback?: (percent: number) => void): Promise<Map<string, E | ApiError>> {
    //     this.onProgressCallback = onProgressCallback;

    //     return new Promise((resolve, reject) => {
    //         if ((entities instanceof Entity && (entities.id === undefined || entities.id === null)) || Array.isArray(entities)) {
    //             const apiRequest = this.buildRequest(HTTPMethod.post, entities);
    //             this.post(apiRequest)
    //                 .then(apiResponse => {
    //                     const apiResponseParsed = this.parse(apiResponse);
    //                     resolve(apiResponseParsed.multi);
    //                 });
    //         } else {
    //             const apiRequest = this.buildRequest(HTTPMethod.put, entities);
    //             this.put(apiRequest)
    //                 .then();
    //         }
    //     });
    // }

    /**
     * 
     * @returns {Promise}
     */
    remove() {
        return new Promise((resolve, reject) => {
            this._buildRequest(ApiRequest.HTTPMethod.DELETE);
            this.delete(this.request)
                .then(apiResponse => {
                    const apiResponseParsed = this._parse(apiResponse);
                    if (apiResponseParsed.error === undefined) {
                        resolve(apiResponseParsed.entity);
                    } else {
                        reject(apiResponseParsed.error);
                    }
                });
        });
    }

    /**
     * 
     * @param {*} method 
     * @param {*} body ?: Entity | FormData | any
     */
    _buildRequest(method = ApiRequest.HTTPMethod.GET, body) {
        this.request.setMethod(method);

        let url = this.getBaseUrl() + this.getRoute();
        for(const [key, value] of this.routeParams) {
            url = url.replace(`/{${key}}`, `/${value}`);
            // console.log(key, value);
        }
        // console.log("_buildRequest", this.getBaseUrl(), this.getRoute(), url);
        this.request.setRoute(url);

        // const headers: Map<string, string> = this.getHeaders();
        // const mergedHeaders: Map<string, string> = new Map([...Array.from(globalHeaders.entries()), ...Array.from(headers.entries())]);

        // if (this.id !== undefined) {
        //     baseUrlString = baseUrlString.replace("/{id}", "/" + this.id);
        // } else {
        //     baseUrlString = baseUrlString.replace("/{id}", "");
        // }

        // vedere dopo
        // let parsedBody 
        if (this.getContentType() === "multipart/form-data" && body !== undefined) {
            const formData = new FormData();
            for ( let key in body ) {
                formData.append(key, body[key]);
            }
            this.request.setBody(formData);
        } else if (body !== undefined && (body instanceof Map || Array.isArray(body))) {
            throw new Error("TODO: Multi Payload not implemented.");
        } else if (body !== undefined) {
            this.request.setBody(body);
        }


        // urlParameters non più usati
        // const url = new URL(baseUrlString);
        // const params = new URLSearchParams(url.search.slice(1));
        // for (const [key, value] of this.urlParameters) {
        //     params.append(key, value);
        // }


        // blocco spostato nel filterByLHSfilter
        // if (this.filterMode !== undefined) {
        //     params.append("mode", this.filterMode);
        // }
        // for (const filter of this.filterCriteria) {
        //     filter.apply(params);
        // }

        // blocco spostato nel orederBy
        // if (this.orderCriteria !== undefined) {
        //     this.orderCriteria.sort(ApiClient.compareOrderCriteria);
        //     const orderParams: string[] = [];
        //     for (const order of this.orderCriteria) {
        //         orderParams.push(order.toString());
        //     }
        //     params.append("sort", orderParams.join("|"));
        // }

        // blocco spostato all'interno di setParam di ApiRequest
        // url.search = params.toString()
        //     .replace(/%5B/g, '[')
        //     .replace(/%5D/g, ']')
        //     .replace(/%7C/g, '|')
        //     .replace(/%3A/g, ':');

        // return new ApiRequest(url, method, mergedHeaders, body);
    }

    /**
     * 
     * @param {ApiResponse} response 
     * @returns 
     */
    _parseMultiple(response) {
        // const result: EntitiesResult<E> = new EntitiesResult<E>();

        const result = {
            status: response.status,
            entities: null,
            error: null
        };

        if (response.body !== undefined) {
            const desObj = this._deserializeResponse(response, true);
            result.entities = desObj.hasOwnProperty('entities') ? desObj.entities : undefined;
            result.error = desObj.hasOwnProperty('error') ? desObj.error : undefined;
        }

        return result;
    }

    /**
     * 
     * @param { ApiResponse } response 
     * @returns 
     */
    _parse(response) {
        // const result: EntityResult<E> = new EntityResult<E>();
        const result = {
            status: response.status,
            entity: null,
            error: null
        };

        // result.statusCode = response.status;
        if (response.body !== undefined) {
            const desObj = this._deserializeResponse(response);
            result.entity = desObj.hasOwnProperty('entity') ? desObj.entity : undefined;
            result.error = desObj.hasOwnProperty('error') ? desObj.error : undefined;
        }

        return result;
    }

    /**
     * 
     * @param {ApiResponse} response 
     * @param {boolean} multipleEntity 
     */
    _deserializeResponse(response, multipleEntity) {
        const result = {};

        if (response.body !== undefined) {
            const statusCode = response.status;
            
            switch (true) {
                case (statusCode === 204):
                    // no response
                    break;
                case (statusCode === 207):
                    // Multi Status - not yet implemented
                    break;
                case (statusCode !== 204 && 200 <= statusCode && statusCode < 400):
                    if (multipleEntity) {
                        result.entities = [];
                        if(Array.isArray(response.body)) {
                            result.entities = response.body;
                        } else {
                            result.entities.push(response.body);
                        }
                    } else {
                        result.entity = response.body;
                    }
                    break;
                case (statusCode >= 400 && statusCode < 500):
                    result.error = new ApiError(response.body);
                    break;
                default:
                    break;
            }
        }

        return result;
    }

    /**
     * 
     * @param {OrderCriteria} a 
     * @param {OrderCriteria} b 
     * @returns 
     */
    compareOrderCriteria(a, b) {
        if (a.getWeight() < b.getWeight()) {
            return -1;
        }
        if (a.getWeight() > b.getWeight()) {
            return 1;
        }
        return 0;
    }

    /**
     * Remove null JSON attribute not included in nullableList fields list
     * 
     * @param {*} json - the json to clean
     * @param {*} nullableList the string array with nullable fields list
     * 
     * @returns 
     */
    static cleanJson(json, nullableList) {
        let data = {...json} 

        Object.keys(data).forEach(key => {
            let value = data[key];
            if(value === null && !nullableList.includes(key)) {
                delete data[key]
            }
        });

        return data;
    }

}