import { JWTAuth } from "../../../lib/js-apiclient/"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class DeviceApiClient extends AdvancedApiClient {

    static entityType = "Device"

    constructor(token, id) {
        super();

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/device/{id}")
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/device")
        }

        this.setEntityType(DeviceApiClient.entityType)
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}