import { JWTAuth } from "../../../lib/js-apiclient/"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class IdentityConfirmationApiClient extends AdvancedApiClient {

    static entityType = "IdentityConfirmation"

    constructor(deviceId, token) {
        super();

        this.setEntityType(IdentityConfirmationApiClient.entityType)
            .setRoute("/api/v1/device/{did}/identity/confirmation");

        this.setRouteParam("did", deviceId);
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}