import { AuthScheme } from "./AuthScheme";
// import { ApiRequest } from "../request/ApiRequest";

export class JWTAuth extends AuthScheme {
    signature = null;

    constructor(signature, name = 'Bearer', location = AuthScheme.Location.header) {
        super(name, location, AuthScheme.SchemeType.http);
        this.signature = signature;
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {ApiRequest}
     */
     apply(request) {
        switch(this.location) {
            case AuthScheme.Location.header:
                // request.headers["Authorization"] = this.name + " " + this.signature;
                request.setHeader("Authorization", this.name + " " + this.signature);
                break;
            case AuthScheme.Location.query:
                // request.params["token"] = this.signature
                request.setParam("token", this.signature);
                break;
            case AuthScheme.Location.cookie:
                throw new Error("JWT Auth inside cookie not implemented");
            default:
                throw new Error("JWT location invalid");
        }

        return request;
    }
}