import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class UserAnalyticsApiClient extends AdvancedApiClient {

    static entityType = "UserData"

    constructor(token, group = "month") {
        super();

        this.setEntityType(UserAnalyticsApiClient.entityType)
        this.setRoute("/api/v1/analytics/user/{group}")
            .setRouteParam("group", group)
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}