import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { AreaModel } from '../models/AreaModel';


const areaAdapter = createEntityAdapter()

export const areaApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'area/fetchAll', 
        async (_, thunkAPI) => {
            try {
                const token = areaApi._getToken(thunkAPI);
                const areaModel = new AreaModel(token);

                return await areaModel.getAreaList();
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'area/create', 
        async ({area}, thunkAPI) => {
            const token = areaApi._getToken(thunkAPI);
            const areaModel = new AreaModel(token);

            try {
                return await areaModel.createArea(area);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'area/update', 
        async ({id, area}, thunkAPI) => {
            const token = areaApi._getToken(thunkAPI);
            const areaModel = new AreaModel(token);
            
            try {
                return await areaModel.updateArea(id, area);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'area/delete', 
        async (area, thunkAPI) => {
            const token = areaApi._getToken(thunkAPI);
            const areaModel = new AreaModel(token);

            try {
                await areaModel.deleteAreaById(area.id);
                return area;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const areaSlice = createSlice({
    name: "area",
    initialState: areaAdapter.getInitialState({
        status: 'idle',
        error: null,
        lastTemplate: null
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        clearAreaList(state, action) {
            areaAdapter.removeAll(state);
        },
        overrideArea(state, action) {
            areaAdapter.upsertOne(state, action.payload)
            state.lastTemplate = null
        }
    },
    extraReducers: {
        [areaApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'area/fetchAll/succeeded'
            state.error = null;
            areaAdapter.setAll(state, action.payload)
        },
        [areaApi.fetchAll.rejected]: (state, action) => {
            state.status = 'area/fetchAll/rejected'
            state.error = action.payload
        },
        [areaApi.create.fulfilled]: (state, action) => {
            state.status = 'area/create/succeeded'
            state.error = null;
            areaAdapter.addOne(state, action.payload)
            state.lastTemplate = action.payload
        },
        [areaApi.create.pending]: (state, action) => {
            state.status = 'area/create/pending';
        },
        [areaApi.create.rejected]: (state, action) => {
            state.status = 'area/create/rejected';
            state.error = action.payload;
        },
        [areaApi.delete.fulfilled]: (state, action) => {
            state.status = 'area/delete/succeeded'
            state.error = null;
            areaAdapter.removeOne(state, action.payload.id)
            state.lastTemplate = null
        },
        [areaApi.delete.rejected]: (state, action) => {
            state.status = 'area/delete/rejected';
            state.error = action.payload;
        },
        [areaApi.update.fulfilled]: (state, action) => {
            state.status = 'area/update/succeeded'
            state.error = null;
            areaAdapter.upsertOne(state, action.payload)
            state.lastTemplate = action.payload
        },
        [areaApi.update.pending]: (state, action) => {
            state.status = 'area/update/pending'
        },
        [areaApi.update.rejected]: (state, action) => {
            state.status = 'area/update/rejected';
            state.error = action.payload;
        },
    }
});

export const {
    selectById: selectAreaById,
    selectAll: selectAllAreas,
} = areaAdapter.getSelectors((state) => state.areaReducer)

export const { setTerm, clearAreaList, overrideArea } = areaSlice.actions;

export default areaSlice.reducer;
