// import { ApiRequest } from "../request/ApiRequest";

export class AuthScheme {

    static SchemeType = {
        http: "http",
        apiKey: "apiKey",
        oauth2: "oauth2", // not implemented
        openIdConnect: "openIdConnect", // not implemented
    }

    static Location = {
        header: "HEADER",
        query: "QUERY",
        cookie: "COOKIE"
    }

    name = "";
    type = AuthScheme.SchemeType.http;
    location = AuthScheme.Location.header;

    constructor(name, location, type) {
        this.name = name;
        this.location = location;
        this.type = type;
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {ApiRequest}
     */
    apply(request) {
        return request;
    }
}