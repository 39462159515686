import {version} from '../package.json';

export const config = {
    mantainance: false,
    dev: true,
    bundle: "tcs.local.bundle",
    version: version,
    acceptApiVersion: '2.0.0', 
    refreshTokenAdvance: 300, //seconds before expire
    apiKey: "0345b778-d86f-11ed-a61b-027654765875",
    baseurl: "http://tcs-api.loc2",
    defaultLanguage: { code: "it", label: "Italian" },
    languages: [
        { code: "gb", label: "English" },
        { code: "it", label: "Italian" },
    ],
    tab_channel: {
        device: "tcs.local.bundle/device",
        mainTab: "tcs.local.bundle/main-tab"
    },
    push_notification: {
        enabled: false,
        apiKey: "xxx",
        config: { // WARNING: Remember to copy this params in /public/firebase-messaging-sw.js
            apiKey: "...",
            authDomain: "...",
            projectId: "...",
            storageBucket: "...",
            messagingSenderId: "...",
            appId: "...",
            measurementId: "..."
        }
    },
    restError: {
        BadRequest: "1000",
        PreconditionFailed: "6000",
        Forbidden: "8000",
    }
};