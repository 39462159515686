import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import moment from 'moment-timezone';
import { UserAnalyticsModel } from '../models/UserAnalyticsModel';


const userDataAdapter = createEntityAdapter()

export const userAnalyticsApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'userAnalytics/fetchAll', 
        async ({groupBy, term, sorting, filters}, thunkAPI) => {
            try {
                const token = userAnalyticsApi._getToken(thunkAPI);
                const userAnalyticsModel = new UserAnalyticsModel(token, groupBy);

                return await userAnalyticsModel.getUserData(term, sorting, filters);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const userAnalyticsSlice = createSlice({
    name: "userAnalytics",
    initialState: userDataAdapter.getInitialState({
        status: 'idle',
        error: null,
    }),
    reducers: {
    },
    extraReducers: {
        [userAnalyticsApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'userAnalyticsApi/fetchAll/succeeded'
            state.error = null;
            userDataAdapter.setAll(state, action.payload)
        },
        [userAnalyticsApi.fetchAll.rejected]: (state, action) => {
            state.status = 'userAnalyticsApi/fetchAll/rejected'
            state.error = action.payload
            userDataAdapter.removeAll()
        },
    }
});

export const {
    selectAll: selectAllUserData,
} = userDataAdapter.getSelectors((state) => state.userAnalyticsReducer)

// export const {  } = userAnalyticsSlice.actions;

export default userAnalyticsSlice.reducer;
