import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { PosModel } from '../models/PosModel';


const posAdapter = createEntityAdapter()

export const posApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'pos/fetchAll', 
        async ({page, term, sorting, filters}, thunkAPI) => {
            try {
                const token = posApi._getToken(thunkAPI);
                const posModel = new PosModel(token);

                /** @type {Pager} */
                const pager = await posModel.getPosList(page, term, sorting, filters);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    fetchById: createAsyncThunk(
        'pos/fetchById', 
        async ({id}, thunkAPI) => {
            const token = posApi._getToken(thunkAPI);
            const posModel = new PosModel(token, id);

            try {
                return await posModel.getPosById(id);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'pos/create', 
        async ({pos}, thunkAPI) => {
            const token = posApi._getToken(thunkAPI);
            const posModel = new PosModel(token);

            try {
                return await posModel.createPos(pos);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'pos/update', 
        async ({id, pos}, thunkAPI) => {
            const token = posApi._getToken(thunkAPI);
            const posModel = new PosModel(token);
            
            try {
                return await posModel.updatePos(id, pos);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'pos/delete', 
        async (pos, thunkAPI) => {
            const token = posApi._getToken(thunkAPI);
            const posModel = new PosModel(token, pos.id);

            try {
                await posModel.deletePosById(pos.id);
                return pos;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const posSlice = createSlice({
    name: "pos",
    initialState: posAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            id: null,
            code: null,
            company_name: 'ASC',
            sign: null,
            group: null,
            type: null,
            address: null,
            state: null,
            avg_score: null,
            num_checks: null,
            last_survey_at: null,
            user_firstname: null,
            user_lastname: null,
            user_email: null,
        },
        filters: {
            code: null,
            company_name: null,
            sign: null,
            group: null,
            type: null,
            address: null,
            cap: null,
            city: null,
            province: null,
            state: null,
            avg_score_min: null,
            avg_score_max: null,
            num_checks_min: null,
            num_checks_max: null,
            last_survey_at_min: null,
            last_survey_at_max: null,
            checked_at_min: null,
            checked_at_max: null,
            user_firstname: null,
            user_lastname: null,
            user_email: null,
        },
        showMore: true,
        lastSavedPos: null
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
        },
        setFilters(state, action) {
          state.filters = action.payload;
        },
        clearPosList(state, action) {
            posAdapter.removeAll(state);
        },
        deleteTempPos(state) {
            posAdapter.removeOne(state, 0)
        },
        setPos(state, action) {
            // console.log('setPos', action.payload)
            posAdapter.upsertOne(state, action.payload)
        }
    },
    extraReducers: {
        [posApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'pos/fetchAll/succeeded'

            if(action.payload.hasPrevPage) {
                posAdapter.addMany(state, action.payload.entities)
            } else {
                posAdapter.setAll(state, action.payload.entities)
            }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [posApi.fetchAll.rejected]: (state, action) => {
            state.status = 'pos/fetchAll/rejected'
            state.error = action.payload
        },
        [posApi.fetchById.fulfilled]: (state, action) => {
            state.status = 'pos/fetchById/succeeded'
            // console.log(state.status, action.payload);
            posAdapter.upsertOne(state, action.payload);
            state.error = null;
        },
        [posApi.fetchById.rejected]: (state, action) => {
            state.status = 'pos/fetchById/rejected'
            state.error = action.payload
            console.log(state.error)
        },
        [posApi.create.fulfilled]: (state, action) => {
            state.status = 'pos/create/succeeded'
            state.error = null;
            // console.log(state.status, action.payload);
            posAdapter.upsertOne(state, action.payload)
            state.lastSavedPos = action.payload
        },
        [posApi.create.pending]: (state, action) => {
            state.status = 'pos/create/pending';
        },
        [posApi.create.rejected]: (state, action) => {
            state.status = 'pos/create/rejected';
            state.error = action.payload;
        },
        [posApi.delete.fulfilled]: (state, action) => {
            state.status = 'pos/delete/succeeded'
            state.error = null;
            posAdapter.removeOne(state, action.payload.id)
        },
        [posApi.delete.rejected]: (state, action) => {
            state.status = 'pos/delete/rejected';
            state.error = action.payload;
        },
        [posApi.update.fulfilled]: (state, action) => {
            state.status = 'pos/update/succeeded'
            state.error = null;
            posAdapter.upsertOne(state, action.payload);
            state.lastSavedPos = action.payload
        },
        [posApi.update.pending]: (state, action) => {
            state.status = 'pos/update/pending'
        },
        [posApi.update.rejected]: (state, action) => {
            state.status = 'pos/update/rejected';
            state.error = action.payload;
        },
    }
});

export const {
    selectById: selectPosById,
    selectAll: selectAllPos,
} = posAdapter.getSelectors((state) => state.posReducer)

export const { setTerm, setSorting, setFilters, clearPosList, setPos, deleteTempPos } = posSlice.actions;

export const getScoreByPosId = createSelector(
    (state, id) => selectPosById(state, id),
    (pos) => {
        let avgScores = []
        let maxScore = 0

        if(pos.areas.length > 0) {
            pos.areas.forEach(area => {
                if(area.templates.length > 0) {
                    area.templates.forEach(template => {
                        avgScores.push(template.avg_score)
                        maxScore += template.max_score
                    })
                }
            })
        }

        const avgScore = avgScores.reduce((a, b) => a + b, 0) / avgScores.length;

        return {
            avg_score: avgScore,
            max_score: maxScore,
        };
    }
);

export default posSlice.reducer;
