import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class TemplateApiClient extends AdvancedApiClient {

    static entityType = "Template"

    constructor(token, areaId, id) {
        super();

        this.setEntityType(TemplateApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/area/{aid}/template/{id}")
                .setRouteParam("aid", areaId)
                .setRouteParam("id", id)
        } else {
            this.setRoute("/api/v1/area/{aid}/template")
                .setRouteParam("aid", areaId)
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}