import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class TemplateQuestionApiClient extends AdvancedApiClient {

    static entityType = "Question"

    constructor(token, tid, id) {
        super();

        this.setEntityType(TemplateQuestionApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/template/{tid}/question/{id}")
                .setRouteParam("tid", tid)
                .setRouteParam("id", id)
        } else {
            this.setRoute("/api/v1/template/{tid}/question")
                .setRouteParam("tid", tid)
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}