export class OrderCriteria {

    static OrderMode = {
        ASC: "ASC",
        DESC: "DESC"
    }

    mode = OrderCriteria.OrderMode.ASC;

    field = undefined;
    weight = 1;

    constructor(field, mode = OrderCriteria.OrderMode.ASC) {
        this.field = field
        this.setMode(mode);
    }

    getField() {
        return this.field;
    }

    setMode(mode) {
        switch(mode) {
            case OrderCriteria.OrderMode.ASC:
            case OrderCriteria.OrderMode.DESC:
                this.mode = mode;
                break;
            default:
                throw new Error(`ERROR: Invalid OrderMode ${mode}`);

        }
        return this;
    }

    setWeight(weight) {
        this.weight = weight;
        return this;
    }

    getWeight() {
        return this.weight;
    }

    toString() {
        return `${this.field}:${this.mode}` ;
    }
}