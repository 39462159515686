// import { ApiRequest } from "../ApiRequest";

export class LHSFilter {

    static LHSOperator = {
        eq: "eq",
        nq: "nq",
        like: "like",
        lt: "lt",
        lte: "lte",
        gt: "gt",
        gte: "gte",
        regex: "regex",
        inside: "inside",
        range: "range"
    }
    static NULL = 'NULL';

    field = undefined;
    values = [];
    operator = LHSFilter.LHSOperator.eq;

    
    constructor(field, operator, values) {
        this.field = field;

        if(this.isValidValues(values)) {
            this.values = Array.isArray(values) ? values : [values];
        } else {
            throw new Error(`ERROR: Invalid values in ${field} filter`);
        }
        
        
        switch(operator) {
            case LHSFilter.LHSOperator.eq:
            case LHSFilter.LHSOperator.nq:
            case LHSFilter.LHSOperator.like:
            case LHSFilter.LHSOperator.lt:
            case LHSFilter.LHSOperator.lte:
            case LHSFilter.LHSOperator.gt:
            case LHSFilter.LHSOperator.gte:
            case LHSFilter.LHSOperator.regex:
            case LHSFilter.LHSOperator.inside:
            case LHSFilter.LHSOperator.range:
                this.operator = operator;
                break;
            default:
                throw new Error(`ERROR: Invalid LHSOperator ${operator}`);
        }

        // console.log("values", this.values)

        switch (this.operator) {
            case LHSFilter.LHSOperator.inside:
                if (this.values.length < 2) throw new Error("[inside] operator needs at least 2 values");
            break;
            case LHSFilter.LHSOperator.range:
                if (this.values.length !== 2) throw new Error("[range] operator needs exactly 2 values");
                break;
            default:
                if  (this.values.length !== 1)  throw new Error(`[${this.operator}] operator needs exactly one value`);
                break;
        }
    }

    isValidValues(values) {
        return true;
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {ApiRequest}
     */
    apply(request) {
        const key = `${this.field}[${this.operator}]`;
        const value = this.values ? this.values.join(":") : LHSFilter.NULL;

        request.setParam(key, value);

        return request;
    }
}