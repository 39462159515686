import { AuthScheme } from "./AuthScheme";
// import { ApiRequest } from "../request/ApiRequest";

export class BasicAuth extends AuthScheme {
    username = null;
    password = null;

    constructor(username, password, name = 'Basic', location = AuthScheme.Location.header) {
        super(name, location, AuthScheme.SchemeType.http);
        this.username = username;
        this.password = password;
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {ApiRequest}
     */
     apply(request) {
         
         switch(this.location) {
             case AuthScheme.Location.header:
                const signature = this.username + ":" + this.password
                const digest = btoa(signature)
                
                // request.headers["Authorization"] = this.name + " " + digest;
                request.setHeader("Authorization", this.name + " " + digest)
                break;
            case AuthScheme.Location.query:
                request.username = this.username;
                request.password = this.password;
                break;
            case AuthScheme.Location.cookie:
                throw new Error("Basic Auth inside cookie not implemented");
            default:
                throw new Error("Basic location invalid");
        }

        return request;
    }
}