// import { config } from '../../config';

import { OrderCriteria } from '../../../lib/js-apiclient';
import { TemplateQuestionApiClient } from './TemplateQuestionApiClient';

export class TemplateQuestionModel {
    _token = null;
    _templateId = null;

    constructor(token, templateId) {
        this._token = (token !== null && token !== undefined) ? token : null
        this._templateId = templateId
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }
    
    async getQuestionsList(term, sorting) {
        const tqClient = new TemplateQuestionApiClient(this._token, this._templateId);

        if(term != null && term.length > 2) tqClient.filterByString(term);
        if (sorting) {
            let orderCriteria = []
            if (sorting.weight !== null) orderCriteria.push((new OrderCriteria('weight', sorting.weight)).setWeight(10))
            if (orderCriteria.length > 0) tqClient.orderBy(orderCriteria);
        }

        return await tqClient.findAll();
    }

    async deleteQuestionById(id) {
        const tqClient = new TemplateQuestionApiClient(this._token, this._templateId, id);
        return tqClient.remove();
    }

    async getQuestionById(id) {
        const tqClient = new TemplateQuestionApiClient(this._token, this._templateId, id);
        return await tqClient.findOne()
    }

    async createQuestion(question) {
        const tqClient = new TemplateQuestionApiClient(this._token, this._templateId);
        return tqClient.save(question);
    }

    async updateQuestion(id, question) {
        const tqClient = new TemplateQuestionApiClient(this._token, this._templateId, id);
        return tqClient.save(question);
    }
}