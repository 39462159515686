import "./assets/scss/style.scss";
import "./lib/utils/stringUtils"

import React, { Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";

import Store from "./Store";
import Spinner from "./layouts/layout-components/spinner/Spinner";

import { deviceApi } from "./modules/identity/reducers/identitySlice"

const App = lazy(
  () =>
    new Promise( async (resolve) => {
      resolve(import("./App"))
    })
);

const Page = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deviceApi.initDevice());
  }, [dispatch]);

  const identity = useSelector((state) => state.identityReducer.identity);
  const status = useSelector((state) => state.identityReducer.status);

  return (
      <>
        <Suspense fallback={<Spinner />}>
          { 
            (
              status === 'device/succeeded' || 
              status === 'login/succeeded' || status === 'login/rejected' ||
              status === 'logout/succeeded' || status === 'logout/rejected' ||
              status === 'recovery/succeeded' ||
              identity
            ) ? <App/> : <Spinner />}
        </Suspense>
      </>
  );
}

ReactDOM.render(
  <Provider store={Store}>
    <Page/>
  </Provider>,
  document.getElementById("root")
);
