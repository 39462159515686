import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { SurveyModel } from '../models/SurveyModel';


const surveyAdapter = createEntityAdapter()

export const surveyApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'survey/fetchAll', 
        async ({page, term, sorting, filters}, thunkAPI) => {
            try {
                const token = surveyApi._getToken(thunkAPI);
                const surveyModel = new SurveyModel(token);

                /** @type {Pager} */
                const pager = await surveyModel.getSurveyList(page, term, sorting, filters);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    // fetchById: createAsyncThunk(
    //     'survey/fetchById', 
    //     async ({posId, templateId, id}, thunkAPI) => {
    //         const token = surveyApi._getToken(thunkAPI);
    //         const surveyModel = new SurveyModel(token);

    //         try {
    //             return await surveyModel.getSurveyById(id);
    //         } catch (apiError) {
    //             return thunkAPI.rejectWithValue(apiError.toJson());
    //         }
    //     }
    // ),
    // create: createAsyncThunk(
    //     'survey/create', 
    //     async ({posId, templateId, survey}, thunkAPI) => {
    //         const token = surveyApi._getToken(thunkAPI);
    //         const surveyModel = new SurveyModel(token, posId, templateId);

    //         try {
    //             return await surveyModel.createSurvey(survey);
    //         } catch (apiError) {
    //             return thunkAPI.rejectWithValue(apiError.toJson());
    //         }
    //     }
    // ),
    update: createAsyncThunk(
        'survey/update', 
        async (survey, thunkAPI) => {
            const token = surveyApi._getToken(thunkAPI);
            const surveyModel = new SurveyModel(token);
            
            try {
                return await surveyModel.updateSurveyById(survey.pos_id, survey.template.id, survey.id, survey);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk(
        'survey/delete', 
        async (survey, thunkAPI) => {
            const token = surveyApi._getToken(thunkAPI);
            const surveyModel = new SurveyModel(token);

            try {
                await surveyModel.deleteSurveyById(survey.pos_id, survey.template.id, survey.id);
                return survey;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const surveySlice = createSlice({
    name: "survey",
    initialState: surveyAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        sorting: {
            id: null,
            closed_at: 'ASC',
            user: null,
            template: null,
            avg_score: null,
        },
        filters: {
            pos_id: null,
            template_id: null,
            area_id: null,
            fromDate: null,
            toDate: null
        },
        showMore: true,
        lastSavedSurvey: null
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
        },
        setFilters(state, action) {
          state.filters = action.payload;
        },
        clearSurveyList(state, action) {
            surveyAdapter.removeAll(state);
        },
        setSurvey(state, action) {
            // console.log(action.payload)
            surveyAdapter.upsertOne(state, action.payload)
        },
        removeSurvey(state, action) {
            surveyAdapter.removeOne(state, action.payload.id)
        },
        removeAllSurvey(state) {
            surveyAdapter.removeAll(state)
        }
    },
    extraReducers: {
        [surveyApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'survey/fetchAll/succeeded'

            surveyAdapter.addMany(state, action.payload.entities)
            // if(action.payload.hasPrevPage) {
            //     surveyAdapter.addMany(state, action.payload.entities)
            // } else {
            //     surveyAdapter.setAll(state, action.payload.entities)
            // }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [surveyApi.fetchAll.rejected]: (state, action) => {
            state.status = 'survey/fetchAll/rejected'
            state.error = action.payload
        },
        // [surveyApi.fetchById.fulfilled]: (state, action) => {
        //     state.status = 'survey/fetchById/succeeded'
        //     surveyAdapter.addOne(state, action.payload);
        //     state.error = null;
        // },
        // [surveyApi.fetchById.rejected]: (state, action) => {
        //     state.status = 'survey/fetchById/rejected'
        //     state.error = action.payload
        // },
        // [surveyApi.create.fulfilled]: (state, action) => {
        //     state.status = 'survey/create/succeeded'
        //     state.error = null;
        //     surveyAdapter.addOne(state, action.payload)
        //     state.lastSavedSurvey = action.payload
        // },
        // [surveyApi.create.pending]: (state, action) => {
        //     state.status = 'survey/create/pending';
        // },
        // [surveyApi.create.rejected]: (state, action) => {
        //     state.status = 'survey/create/rejected';
        //     state.error = action.payload;
        // },
        [surveyApi.delete.fulfilled]: (state, action) => {
            state.status = 'survey/delete/succeeded'
            state.error = null;
            surveyAdapter.removeOne(state, action.payload.id)
        },
        [surveyApi.delete.rejected]: (state, action) => {
            state.status = 'survey/delete/rejected';
            state.error = action.payload;
        },
        [surveyApi.update.fulfilled]: (state, action) => {
            state.status = 'survey/update/succeeded'
            state.error = null;
            surveyAdapter.upsertOne(state, action.payload);
            state.lastSavedSurvey = action.payload
        },
        [surveyApi.update.pending]: (state, action) => {
            state.status = 'survey/update/pending'
        },
        [surveyApi.update.rejected]: (state, action) => {
            state.status = 'survey/update/rejected';
            state.error = action.payload;
        },
    }
});

export const selectSurveyByAreaId = createSelector(
    (state, _) => selectAllSurvey(state),
    (_, areaId) => areaId,
    (surveyData, areaId) => {
        return areaId ? surveyData.filter(survey => survey.template.area_id === areaId) : surveyData;
    }
);

export const {
    selectById: selectSurveyById,
    selectAll: selectAllSurvey,
} = surveyAdapter.getSelectors((state) => state.surveyReducer)

export const { setTerm, setSorting, setFilters, clearSurveyList, setSurvey, removeSurvey, removeAllSurvey } = surveySlice.actions;

export default surveySlice.reducer;
