import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class SurveyApiClient extends AdvancedApiClient {

    static entityType = "Survey"

    constructor(token) {
        super();

        this.setEntityType(SurveyApiClient.entityType);
        this.setRoute("/api/v1/survey")
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}