import { OrderCriteria } from '../../../lib/js-apiclient';
import { TemplateApiClient } from './TemplateApiClient';

export class TemplateModel {
    _token = null;
    _areaId = null;

    constructor(token, areaId) {
        this._token = (token !== null && token !== undefined) ? token : null
        this._areaId = areaId
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }
    
    async getTemplateList(term, sorting) {
        const templateClient = new TemplateApiClient(this._token, this._areaId);

        if(term != null && term.length > 2) templateClient.filterByString(term);
        if (sorting) {
            let orderCriteria = []
            if (sorting.name !== null) orderCriteria.push((new OrderCriteria('name', sorting.weight)).setWeight(10))
            if (orderCriteria.length > 0) templateClient.orderBy(orderCriteria);
        }

        return await templateClient.findAll();
    }

    async deleteTemplateById(id) {
        const templateClient = new TemplateApiClient(this._token, this._areaId, id);
        return templateClient.remove();
    }

    async getTemplateById(id) {
        const templateClient = new TemplateApiClient(this._token, this._areaId, id);
        return await templateClient.findOne()
    }

    async createTemplate(template) {
        const templateClient = new TemplateApiClient(this._token, this._areaId);
        return templateClient.save(template);
    }

    async updateTemplate(id, template) {
        const templateClient = new TemplateApiClient(this._token, this._areaId, id);
        return templateClient.save(template);
    }
}