import { LHSFilter, LHSStringFilter, OrderCriteria } from "../../../lib/js-apiclient";
import { DeviceModel } from "../../identity/models/DeviceModel";
import { NotificationApiClient } from "./NotificationApiClient";
import { config } from "../../../config";

export class NotificationModel {
    _token = null;

    /** @type {Pager} */
    _pager = null;

    _device = null;

    constructor(token) {
        this._device = DeviceModel.getInstance().getDevice();
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getLastNotifications(term = null) {
        let result = [];
        
        if(config.push_notification.enabled) {
            const notificationClient = new NotificationApiClient(this._token, this._device.id);
            notificationClient.setPagingStep(100);
    
            let orderCriteria = [ 
                (new OrderCriteria("id", OrderCriteria.OrderMode.DESC)), 
            ];
            let lhsFilters = [
                (new LHSStringFilter('read_at', LHSFilter.LHSOperator.eq, "NULL"))
            ];
    
            if(term != null && term.length > 2) notificationClient.filterByString(term);
            notificationClient
                .filterByLHSFilter(lhsFilters)
                .orderBy(orderCriteria);
    
            const pager = await notificationClient.fetchPage(0);
            result = pager.getEntities();
        }

        return result
    }

    async updateByTag(notification) {
        const notificationClient = new NotificationApiClient(this._token, this._device.id, notification.tag);
        return await notificationClient.save(notification);
    }
}