import {LHSFilter} from "./LHSFilter";

export class LHSStringFilter extends LHSFilter {
    
    isValidValues(values) {
        let result = false;

        if(Array.isArray(values)) {
            result = values.every((value) => {
                return typeof value === 'string' || value instanceof String;
            });
        } else {
            result = typeof values === 'string' || values instanceof String;
        }

        return result;
    }

}