// import { config } from '../../config';

import { LHSFilter, LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../lib/js-apiclient';
import { AdvancedSurveyApiClient } from './AdvancedSurveyApiClient';
import { ResponseApiClient } from './ResponseApiClient';
import { ResponseMediaApiClient } from './ResponseMediaApiClient';
import { SurveyApiClient } from './SurveyApiClient';

export class SurveyModel {
    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }
    
    async getSurveyList(page, term, sorting, filters) {
        const surveyClient = new SurveyApiClient(this._token);
        surveyClient.setPagingStep(50);

        if(term != null && term.length > 2) surveyClient.filterByString(term);
        if (sorting) {
            let orderCriteria = []
            if (sorting.closed_at !== null) orderCriteria.push((new OrderCriteria('closed_at', sorting.closed_at)).setWeight(10))
            if (sorting.avg_score !== null) orderCriteria.push((new OrderCriteria('avg_score', sorting.avg_score)).setWeight(20))
            if (orderCriteria.length > 0) surveyClient.orderBy(orderCriteria);
        }
        if(filters) {
            let filterCriteria = []
            if (filters.pos_id !== null) filterCriteria.push(new LHSIntFilter('pos_id', Array.isArray(filters.pos_id) ? LHSFilter.LHSOperator.inside : LHSFilter.LHSOperator.eq, filters.pos_id))
            if (filters.area_id !== null) filterCriteria.push(new LHSIntFilter('area_id', Array.isArray(filters.area_id) ? LHSFilter.LHSOperator.inside : LHSFilter.LHSOperator.eq, filters.area_id))
            if (filters.template_id !== null) filterCriteria.push(new LHSIntFilter('template_id', Array.isArray(filters.template_id) ? LHSFilter.LHSOperator.inside : LHSFilter.LHSOperator.eq, filters.template_id))
            if (filters.fromDate !== null && filters.toDate !== null) {
                // filterCriteria.push(new LHSStringFilter('closed_at', LHSFilter.LHSOperator.range, [filters.fromDate, filters.toDate]))
                filterCriteria.push(new LHSStringFilter('updated_at', LHSFilter.LHSOperator.range, [filters.fromDate, filters.toDate]))
                if (!filters.showHidden) filterCriteria.push(new LHSStringFilter('closed_at', LHSFilter.LHSOperator.range, [filters.fromDate, filters.toDate]))
            }

            if (filterCriteria.length > 0) surveyClient.filterByLHSFilter(filterCriteria);
        }

        return await surveyClient.fetchPage(page);
    }

    async deleteSurveyById(posId, templateId, id) {
        const surveyClient = new AdvancedSurveyApiClient(this._token, posId, templateId, id);
        return surveyClient.remove();
    }

    async updateSurveyById(posId, templateId, id, survey) {
        const surveyClient = new AdvancedSurveyApiClient(this._token, posId, templateId, id);
        return surveyClient.save(survey);
    }

    async getSurveyResponse(surveyId) {
        const responseClient = new ResponseApiClient(this._token, surveyId)
        return await responseClient.findAll()
    }

    async getSurveyResponseMedia(page, surveyId, responseId) {
        const responseMediaClient = new ResponseMediaApiClient(this._token, surveyId, responseId)
        responseMediaClient.setPagingStep(10);

        return await responseMediaClient.fetchPage(page);
    }
}