import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";

import settingReducer from "./modules/settings/reducers/settingsSlice";
import identityReducer from "./modules/identity/reducers/identitySlice";
import userReducer from "./modules/user/reducers/userSlice";
import userAnalyticsReducer from "./modules/user/reducers/userAnalyticsSlice";
import notificationReducer from "./modules/push-notification/reducers/notificationSlice";
import posReducer from "./modules/pos/reducers/posSlice";
import posAnalyticsReducer from "./modules/pos/reducers/posAnalyticsSlice";
import surveyReducer from "./modules/pos/reducers/surveySlice";
import responseReducer from "./modules/pos/reducers/responseSlice";
import responseMediaReducer from "./modules/pos/reducers/responseMediaSlice";
import areaReducer from "./modules/pos/reducers/areaSlice";
import templateReducer from "./modules/pos/reducers/templateSlice";
import templateQuestionReducer from "./modules/pos/reducers/templateQuestionSlice";

const Store = configureStore({
  reducer: combineReducers({
    identityReducer,
    userReducer,
    userAnalyticsReducer,
    settingReducer,
    notificationReducer,
    posReducer,
    posAnalyticsReducer,
    surveyReducer,
    responseReducer,
    responseMediaReducer,
    areaReducer,
    templateReducer,
    templateQuestionReducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default Store;