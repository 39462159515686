import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class UserApiClient extends AdvancedApiClient {

    static entityType = "User"

    constructor(token, id) {
        super();

        this.setEntityType(UserApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/user/{uid}")
                .setRouteParam("uid", id);
        } else {
            this.setRoute("/api/v1/user")
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}