export class ApiError {
    code = 0;
    message = "";
    url = ""

    constructor(data) {
        this.code = data.code;
        this.message = data.message;
        this.url = data.url;
    }

    toJson() {
        return {
            code: this.code,
            message: this.message,
            url: this.url
        }
    }
}