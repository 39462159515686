import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class UserMediaApiClient extends AdvancedApiClient {

    static entityType = "Media"

    constructor(token, uid, id) {
        super();

        this.setEntityType(UserMediaApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/user/{uid}/media/{id}")
                .setRouteParam("uid", uid)
                .setRouteParam("id", id);
        } else {
            this.setRoute("/api/v1/user/{uid}/media")
                .setRouteParam("uid", uid);
        }

        this.appendAuthSchema(new JWTAuth(token))
    }

}