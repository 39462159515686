import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ResponseApiClient extends AdvancedApiClient {

    static entityType = "Response"

    constructor(token, surveyId, id) {
        super();

        this.setEntityType(ResponseApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/survey/{sid}/response/{id}")
                .setRouteParam("sid", surveyId)
                .setRouteParam("id", id)
        } else {
            this.setRoute("/api/v1/survey/{sid}/response")
                .setRouteParam("sid", surveyId)
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}