import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,  
} from '@reduxjs/toolkit'
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { PosAnalyticsModel } from '../models/PosAnalyticsModel';
import moment from 'moment-timezone';


const posDataAdapter = createEntityAdapter()

export const posAnalyticsApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'posAnalytics/fetchAll', 
        async ({name, type, group, fromDate, toDate, term, filters}, thunkAPI) => {
            try {
                const token = posAnalyticsApi._getToken(thunkAPI);
                const posAnalyticsModel = new PosAnalyticsModel(token, type, group);

                /** @type {Pager} */
                const pager = await posAnalyticsModel.getPosData(name, fromDate, toDate, term, filters);
                
                return {
                    hasPrevPage: pager.hasPrevPage,
                    hasNextPage: pager.hasNextPage,
                    entities: pager.getEntities()
                }
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const posAnalyticsSlice = createSlice({
    name: "posAnalytics",
    initialState: posDataAdapter.getInitialState({
        status: 'idle',
        error: null,
        term: null,
        filters: {
            pos_id: null,
            area_id: null,
            template_id: null,
            sign: null,
            group: null,
        },
        fromDate: moment().subtract(30, "days"),
        toDate: moment(),
        groupBy: PosAnalyticsModel.GROUP_DAILY,
        showMore: true
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setFilters(state, action) {
          state.filters = action.payload;
        },
        setFromDate(state, action) {
          state.fromDate = action.payload;
        },
        setToDate(state, action) {
          state.toDate = action.payload;
        },
        setGroupBy(state, action) {
          state.groupBy = action.payload;
        },
        clearPosDataList(state, action) {
            posDataAdapter.removeAll(state);
        },
        removePosDataByName(state, action) {
            posDataAdapter.removeMany(state, action.payload.ids)
        }
    },
    extraReducers: {
        [posAnalyticsApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'posAnalytics/fetchAll/succeeded'

            posDataAdapter.addMany(state, action.payload.entities)
            // if(action.payload.hasPrevPage) {
            //     posDataAdapter.addMany(state, action.payload.entities)
            // } else {
            //     posDataAdapter.setAll(state, action.payload.entities)
            // }

            state.showMore = action.payload.hasNextPage;
            state.error = null;
        },
        [posAnalyticsApi.fetchAll.rejected]: (state, action) => {
            state.status = 'posAnalytics/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectAll: selectAllPosData,
} = posDataAdapter.getSelectors((state) => state.posAnalyticsReducer)

// export const selectPosDataBySerieName = createSelector(
//     (state, _) => selectAllPosData(state),
//     (posData, name) => {
//         console.log(name)
//         return posData
//     }
// );

export const { setTerm, setFilters, clearPosDataList, removePosDataByName, setFromDate, setToDate, setGroupBy } = posAnalyticsSlice.actions;

export default posAnalyticsSlice.reducer;
