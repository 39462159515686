
import {v4 as uuidv4} from 'uuid';
import { config } from '../../config'

export class TabDelegate {

    onOpenTab(tab, isElected) {
        throw new Error("Override onOpen abstract class method");
    }

    onCloseTab(tab, isElected) {
        throw new Error("Override onClose abstract class method");
    }
}

export default class TabManager {

    _tab = null;
    _tabs = [];
    _pingInterval = 60 * 1000; //milliseconds
    _channels = [];
    _subscribedChannels = [];

    _bundle = config.bundle;

    static MSG_OPEN_TAB = "open_tab";
    static MSG_CLOSE_TAB = "close_tab";
    static MSG_PING_TAB = "ping_tab";

    /** @type {TabDelegate} */
    _tabDelegate = null;

    /** @type {TabManager} */
    static _instance = null;

    constructor() {
        if (TabManager._instance) {
            throw new Error("Singleton classes can't be instantiated more than once.")
        }

        this._tab = JSON.parse(window.sessionStorage.getItem("tab"));

        if(!this._tab) {
            this._tab = {
                uuid: uuidv4(), 
                createdAt: (new Date()).getTime()
            };
            window.sessionStorage.setItem("tab", JSON.stringify(this._tab));
        }

        this._reloadChannels();
        this._channelMonitor();
        this._electMainTab();
    }

    /**
     * 
     * @returns {TabManager}
     */
    static getInstance() {
        if(TabManager._instance === null) {
            this._instance = new TabManager();
        }

        return this._instance;
    }

    setDelegate(tabDelegate) {
        this._tabDelegate = tabDelegate;
    }

    _electMainTab() {
        const mainTabChannel = config.tab_channel.mainTab;

        if(!this.existsChannel(mainTabChannel)) {
            this.createChannel(mainTabChannel);
        }

        this.subscribeChannel(mainTabChannel, (message, channel_name) => {
            const fromTab = message.fromTab;

            switch(message.payload) {
                case TabManager.MSG_OPEN_TAB:
                    // console.log("opened tab", fromTab);
                    this._setMainTab(this._tab);
                    this._incTabCounter();
                    if(this._tabDelegate) {
                        const isElected = this.isElectedTab();
                        this._tabDelegate.onOpenTab(fromTab, isElected);
                    }

                    break;
                case TabManager.MSG_CLOSE_TAB:
                    // console.log("closed tab", fromTab);
                    this._removeTab(fromTab);
                    this._unsetMainTab(fromTab);
                    this._setMainTab(this._tab);
                    this._decTabCounter();
                    const isElected = this.isElectedTab();

                    if(isElected) {
                        setTimeout(() => {
                            this.clearChannel(channel_name);
                        }, 100);
                    }
                    if(this._tabDelegate) {
                        this._tabDelegate.onCloseTab(fromTab, isElected);
                    }
                    break;
                    case TabManager.MSG_PING_TAB:
                        this._appendTab(fromTab);
                        break;
                default:
                    throw new Error("Invalid message on "+channel_name);
            }
        });

        setInterval(function() {
            this._pingOtherTab();
        }.bind(this), this._pingInterval);

        this._setMainTab(this._tab);
        this._appendTab(this._tab);
        this._pingOtherTab();
        this.sendMessage(mainTabChannel,TabManager.MSG_OPEN_TAB);

        window.addEventListener('beforeunload', function(event) {
            this._unsetMainTab(this._tab);
            this.sendMessage(mainTabChannel,TabManager.MSG_CLOSE_TAB);
        }.bind(this));
    }

    _pingOtherTab() {
        const mainTabChannel = config.tab_channel.mainTab;
        this.sendMessage(mainTabChannel,TabManager.MSG_PING_TAB);
        // console.log(TabManager.MSG_PING_TAB);
    }

    _appendTab(newTab) {
        let t1 = this._tabs.find(function(tab, index) {
            return (tab.uuid === newTab.uuid);
        });

        if(!t1) {
            this._tabs.push(newTab);
            this._tabs.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
        }
        // console.log(this._tabs);

        const mainTab = this.getElectedTab();
        let t2 = this._tabs.find(function(tab, index) {
            return (tab.uuid === mainTab.uuid);
        });

        if(!t2) {
            window.localStorage.setItem("tab_counter", this._tabs.length);
            window.localStorage.removeItem("tab_main");
            this._setMainTab(this._tabs[0]);
        }
    }

    _removeTab(tab) {
        this._tabs = this._tabs.filter(function( t ) {
            return t.uuid !== tab.uuid;
        });
        window.localStorage.setItem("tab_counter", this._tabs.length);
        // console.log(this._tabs);
    }

    getTab() {
        return this._tab;
    }

    isLastTab() {
        const counter = JSON.parse(window.localStorage.getItem("tab_counter"));
        return(counter === null || counter === undefined || counter <= 0);
    }

    _incTabCounter() {
        if(this.isElectedTab()) {
            let counter = JSON.parse(window.localStorage.getItem("tab_counter")) + 1;
            window.localStorage.setItem("tab_counter",JSON.stringify(counter));
        }
    }

    _decTabCounter() {
        if(this.isElectedTab()) {
            let counter = JSON.parse(window.localStorage.getItem("tab_counter")) - 1;
            window.localStorage.setItem("tab_counter",JSON.stringify(counter));
        }
    }

    _unsetMainTab(tab) {
        const mainTab = JSON.parse(window.localStorage.getItem("tab_main"));
        if(mainTab !== null && mainTab.uuid === tab.uuid) {
            window.localStorage.removeItem("tab_counter");
            window.localStorage.removeItem("tab_main");
        }
    }

    _setMainTab(tab) {
        const mainTab = JSON.parse(window.localStorage.getItem("tab_main"));
        // console.log("_setMainTab", mainTab, tab);
        if(mainTab === null || mainTab.createdAt > tab.createdAt) {
            window.localStorage.setItem("tab_main", JSON.stringify(tab));
        }
    }

    _reloadChannels() {
        const channels = JSON.parse(window.localStorage.getItem("tab_channels"));
        if(channels) this._channels = channels;
    }

    _channelMonitor() {
        window.addEventListener('storage', function(event) {

            if(this._subscribedChannels) {
                this._subscribedChannels.forEach(({name, onMessageReceived, onSubscription}) => {
                    if (event.key === "tab_channels/"+name) {
                        const message = JSON.parse(window.localStorage.getItem("tab_channels/"+name));
                        if(message && onMessageReceived) {
                            onMessageReceived(message, name);
                        }
                    }
                });
            }
        }.bind(this));
    }

    getElectedTab() {
        const mainTab = JSON.parse(window.localStorage.getItem("tab_main"));
        return mainTab;
    }

    isElectedTab() {
        const mainTab = this.getElectedTab();
        return (mainTab.uuid === this._tab.uuid);
    }

    existsChannel(name) {
        this._reloadChannels();
        return this._channels.includes(name);
    }

    createChannel(name) {
        if(!this.existsChannel(name)) {
            this._channels.push(name);
            window.localStorage.setItem("tab_channels", JSON.stringify(this._channels));
        }
    }

    deleteChannel(name) {        
        if(this.existsChannel(name)) {
            delete this._channels[name];
            window.localStorage.setItem("tab_channels", JSON.stringify(this._channels));
            window.localStorage.removeItem("tab_channels/"+name);
        }
    }

    _writeMessageOnStore(channel_name, payload) {
        const message = {
            fromTab: this._tab,
            createdAt: (new Date()).getTime(),
            payload: payload
        }

        window.localStorage.setItem("tab_channels/"+channel_name, JSON.stringify(message));

        setTimeout(() => {
            this.clearChannel(channel_name);
        }, 100);
    }

    _sendSubscriptionMessage(channel_name) {
        if(this.existsChannel(channel_name)) {
            this._writeMessageOnStore(channel_name+"/subscription", null);
        } else {
            // console.log("Channel to subscribe not exists", this._channels, channel_name);
            throw new Error("Channel to subscribe not exists ("+channel_name+")");
        }
    }

    sendMessage(channel_name, payload) {
        if(this.existsChannel(channel_name)) {
            this._writeMessageOnStore(channel_name, payload)
        } else {
            // console.log("Channel not exists", this._channels, channel_name);
            throw new Error("Channel "+channel_name+" not exists");
        }
    }

    clearChannel(channel_name) {
        window.localStorage.removeItem("tab_channels/"+channel_name);
    }

    subscribeChannel(name, onMessageReceivedCallback) {
        if(this.existsChannel(name)) {
            this._subscribedChannels.push({
                name: name, 
                onMessageReceived: onMessageReceivedCallback,
                // onSubscription: onSubscriptionCallback,
            });
        }
    }

    unsubscribeChannel(name) {
        if(this._subscribedChannels) {
            this._subscribedChannels.forEach((channel) => {
                if(channel.name === name) {
                    delete this._subscribedChannels[channel];
                }
            });
        }
    }
}