import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
    createSelector,
} from '@reduxjs/toolkit'
import { TemplateQuestionModel } from '../models/TemplateQuestionModel';


const templateQuestionAdapter = createEntityAdapter()

export const templateQuestionApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'templateQuestion/fetchAll', 
        async ({templateId, term, sorting}, thunkAPI) => {
            try {
                const token = templateQuestionApi._getToken(thunkAPI);
                const templateQuestionModel = new TemplateQuestionModel(token, templateId);

                return await templateQuestionModel.getQuestionsList(term, sorting);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'templateQuestion/create', 
        async ({templateId, question}, thunkAPI) => {
            const token = templateQuestionApi._getToken(thunkAPI);
            const templateQuestionModel = new TemplateQuestionModel(token, templateId);

            try {
                return await templateQuestionModel.createQuestion(question);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    updates: createAsyncThunk(
        'templateQuestion/updates', 
        async ({templateId, questions}, thunkAPI) => {
            const token = templateQuestionApi._getToken(thunkAPI);
            const templateQuestionModel = new TemplateQuestionModel(token, templateId);
            
            try {
                let promises = []
                questions.forEach((question) => {
                    promises.push(templateQuestionModel.updateQuestion(question.id, question))
                })
                return Promise.all(promises)
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'templateQuestion/update', 
        async ({templateId, question}, thunkAPI) => {
            const token = templateQuestionApi._getToken(thunkAPI);
            const templateQuestionModel = new TemplateQuestionModel(token, templateId);
            
            try {
                return await templateQuestionModel.updateQuestion(question.id, question);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk( //scrivere tutti gli extra reducers
        'templateQuestion/delete', 
        async ({templateId, question}, thunkAPI) => {
            const token = templateQuestionApi._getToken(thunkAPI);
            const templateQuestionModel = new TemplateQuestionModel(token, templateId);

            try {
                await templateQuestionModel.deleteQuestionById(question.id);
                return question;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const templateQuestionSlice = createSlice({
    name: "templateQuestion",
    initialState: templateQuestionAdapter.getInitialState({
        status: 'idle',
        error: null,
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        clearQuestionList(state, action) {
            templateQuestionAdapter.removeAll(state);
        }
    },
    extraReducers: {
        [templateQuestionApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'templateQuestion/fetchAll/succeeded'
            state.error = null;
            
            templateQuestionAdapter.setAll(state, action.payload)
        },
        [templateQuestionApi.fetchAll.rejected]: (state, action) => {
            state.status = 'templateQuestion/fetchAll/rejected'
            state.error = action.payload
        },
        [templateQuestionApi.create.fulfilled]: (state, action) => {
            state.status = 'templateQuestion/create/succeeded'
            state.error = null;
            templateQuestionAdapter.addOne(state, action.payload)
            state.lastSavedPos = action.payload
        },
        [templateQuestionApi.create.pending]: (state, action) => {
            state.status = 'templateQuestion/create/pending';
        },
        [templateQuestionApi.create.rejected]: (state, action) => {
            state.status = 'templateQuestion/create/rejected';
            state.error = action.payload;
        },
        [templateQuestionApi.delete.fulfilled]: (state, action) => {
            state.status = 'templateQuestion/delete/succeeded'
            state.error = null;
            templateQuestionAdapter.removeOne(state, action.payload.id)
        },
        [templateQuestionApi.delete.rejected]: (state, action) => {
            state.status = 'templateQuestion/delete/rejected';
            state.error = action.payload;
        },
        [templateQuestionApi.update.fulfilled]: (state, action) => {
            state.status = 'templateQuestion/update/succeeded'
            state.error = null;
            templateQuestionAdapter.upsertOne(state, action.payload);
        },
        [templateQuestionApi.update.pending]: (state, action) => {
            state.status = 'templateQuestion/update/pending'
        },
        [templateQuestionApi.update.rejected]: (state, action) => {
            state.status = 'templateQuestion/update/rejected';
            state.error = action.payload;
        },
        [templateQuestionApi.updates.fulfilled]: (state, action) => {
            state.status = 'templateQuestion/updates/succeeded'
            state.error = null;

            // console.log('templateQuestion/updates/succeeded', action.payload);
            templateQuestionAdapter.upsertMany(state, action.payload)
        },
        [templateQuestionApi.updates.pending]: (state, action) => {
            state.status = 'templateQuestion/updates/pending'
        },
    }
});

export const selectAllQuestionsOrderedByWeight = createSelector(
    (state) => selectAllQuestions(state),
    (questions) => {
        return questions.sort((a, b) => a.weight - b.weight)
    }
);

export const {
    selectById: selectQuestionById,
    selectAll: selectAllQuestions,
} = templateQuestionAdapter.getSelectors((state) => state.templateQuestionReducer)

export const { setTerm, clearQuestionList } = templateQuestionSlice.actions;

export default templateQuestionSlice.reducer;
