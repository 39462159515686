import axios from "axios";
import qs from "qs";

import { ApiRequest } from "./request/ApiRequest";
import { ApiResponse } from "./response/ApiResponse";

export class RequestManager {
    // private api: AxiosInstance;
    static baseUrl = "";
    static globalHeaders = new Map();

    timeout = 10000;

    contentType = 'application/json';

    authSchemas = [];

    // protected onProgressCallback?: (percent: number) => void;
    onProgressCallback;

    // protected constructor() {
    //     this.api = axios.create();
    //     this.baseUrl = undefined;
    //     this.globalHeaders = new Map();
    //     this.authSchemas = [];

    //     // Override configs default for the axios library
    //     this.api.defaults.headers.post['Content-Type'] = 'application/json';
    //     this.api.defaults.timeout = 10000;
    //     this.api.defaults.paramsSerializer = (params: PathLike) => qs.stringify(params, {arrayFormat: 'brackets'});
    //     this.api.defaults.validateStatus = (status: number) => status >= 200 && status < 400;
    // }

    setTimeout(seconds) {
        this.timeout = seconds;
        return this;
    }

    setContentType(type) {
        this.contentType = type;
        return this;
    }

    getContentType() {
        return this.contentType;
    }

    setBaseUrl(url) {
        RequestManager.baseUrl = url;
        return this;
    }

    getBaseUrl() {
        return RequestManager.baseUrl;
    }

    static addGlobalHeader(key, value) {
        RequestManager.globalHeaders.set(key, value);
    }

    static removeGlobalHeader(key) {
        if (RequestManager.globalHeaders.has(key)) {
            RequestManager.globalHeaders.delete(key);
        }
    }

    static setGlobalHeader(key, value) {
        RequestManager.globalHeaders.set(key, value);
    }

    static getGlobalHeader(key) {
        return RequestManager.globalHeaders.get(key);
    }

    static getGlobalHeaders() {
        return RequestManager.globalHeaders;
    }

    appendAuthSchema(auth) {
        this.authSchemas.push(auth);
        return this;
    }

    getAuthSchemas() {
        return this.authSchemas;
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {Promise}
     */
    get(request) {
        if (request.getMethod() === ApiRequest.HTTPMethod.GET) {
            return this._execute(request);
        }
        throw new Error('Invalid method passed into `ApiRequest`. Expected GET.');
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {Promise}
     */
    post(request) {
        if (request.getMethod() === ApiRequest.HTTPMethod.POST) {
            return this._execute(request);
        }
        throw new Error('Invalid method passed into `ApiRequest`. Expected POST.');
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {Promise}
     */
    put(request) {
        if (request.getMethod() === ApiRequest.HTTPMethod.PUT) {
            return this._execute(request);
        }
        throw new Error('Invalid method passed into `ApiRequest`. Expected PUT.');
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {Promise}
     */
    patch(request) {
        if (request.getMethod() === ApiRequest.HTTPMethod.PATCH) {
            return this._execute(request);
        }
        throw new Error('Invalid method passed into `ApiRequest`. Expected PATCH.');
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {Promise}
     */
    delete(request) {
        if (request.getMethod() === ApiRequest.HTTPMethod.DELETE) {
            return this._execute(request);
        }
        throw new Error('Invalid method passed into `ApiRequest`. Expected DELETE.');
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {Promise}
     */
    _execute(request) {
        
        return new Promise((resolve, reject) => {

            for (const [key, value] of RequestManager.getGlobalHeaders()) {
                request.setHeader(key, value);
                // request.headers[key] = value;
            }

            for (const schema of this.authSchemas) {
                request = schema.apply(request);
            }

            const options = this._getRequestOptions(request);

            // Override configs default for the axios library
            axios.defaults.headers.post['Content-Type'] = this.contentType;
            axios.defaults.timeout = this.timeout;
            axios.defaults.paramsSerializer = (params) => qs.stringify(params, {arrayFormat: 'brackets'});
            axios.defaults.validateStatus = (status) => status >= 200 && status < 400;
            
            axios.request(options)
                .then(response => {
                    const headers = new Map();
                    if(response.headers !== undefined) {
                        Object.keys(response.headers).forEach(key => {
                            headers.set(key, response.headers[key]);
    
                            // const indexOfStorage = key.indexOf('x-storage');
                            // if (indexOfStorage >= 0) {
                            //     localStorage.setItem(key, response.headers[key]);
                            // }
                        })
                    }
                    const apiResponse = new ApiResponse(response.status, headers, response.data);

                    resolve(apiResponse); // fulfilled
                })
                .catch(error => {
                    // tslint:disable-next-line:no-console
                    // console.log(error);
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx or 3xx
                        const apiResponse = new ApiResponse(error.response.status, error.response.headers, error.response.data);
                        resolve(apiResponse); // fulfilled
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        const apiResponse = new ApiResponse(error.request.status, error.request.headers, error.request.data);
                        reject(apiResponse); // rejected
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        reject(0, ' Unknown Error: ' + error.message, null); // rejected
                    }
                });
        });
    }

    /**
     * Generate Axios config object starting drom ApiRequest
     * 
     * @param {ApiRequest} request 
     * @returns {Object}
     */
    _getRequestOptions(request) {
        let options = {};

        options.method = request.getMethod();
        options.url = request.getRoute();

        options.headers = {};
        for (const [key, value] of request.getHeaders()) {
            options.headers[key] = value;
        }
        
        options.params = {};
        for (const [key, value] of request.getParams()) {
            options.params[key] = value;
        }

        if (request.method === ApiRequest.HTTPMethod.POST || request.method === ApiRequest.HTTPMethod.PUT || request.method === ApiRequest.HTTPMethod.PATCH) {
            options.data = request.body;
        }

        // options.onUploadProgress = (progressEvent) => {
        //     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //     this.onProgressCallback(percentCompleted);
        // };

        // console.log("AxiosRequestConfig", options);

        return options;
    }
}