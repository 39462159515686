// import { config } from '../../config';

import { ApiClient, LHSIntFilter, LHSStringFilter } from '../../../lib/js-apiclient';
import { Pager } from '../../../lib/js-apiclient/src/response/Pager';
import { PosAnalyticsApiClient } from './PosAnalyticsApiClient';

export class PosAnalyticsModel {

    static TYPE_AVG_SCORE = "avg_score"
    static TYPE_TOT_SCORE = "tot_score"

    static GROUP_DAILY = "day"
    static GROUP_WEEKLY = "week"
    static GROUP_MONTHLY = "month"
    static GROUP_YEARLY = "year"

    _token = null;
    _type = null;
    _group = null;

    constructor(token, type, group) {
        this._token = (token !== null && token !== undefined) ? token : null
        this._type = type
        this._group = group
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }
    
    async getPosData(name, fromDate, toDate, term, filters) {
        const posDataClient = new PosAnalyticsApiClient(this._token, this._type, this._group);
        posDataClient.setPagingStep(10);

        if(term != null && term.length > 2) posDataClient.filterByString(term);
        
        if (filters) {
            let mode = ApiClient.FilterMode.AND
            let filterCriteria = [];

            if (filters.pos_id !== null && filters.pos_id !== undefined) filterCriteria.push(new LHSIntFilter('pos_id', LHSIntFilter.LHSOperator.eq, filters.pos_id))
            if (filters.area_id !== null && filters.area_id !== undefined) filterCriteria.push(new LHSIntFilter('area_id', LHSIntFilter.LHSOperator.eq, filters.area_id))
            if (filters.template_id !== null && filters.template_id !== undefined) filterCriteria.push(new LHSIntFilter('template_id', LHSIntFilter.LHSOperator.eq, filters.template_id))
            if (filters.sign !== null && filters.sign !== undefined) filterCriteria.push(new LHSStringFilter('sign', LHSStringFilter.LHSOperator.like, filters.sign))
            if (filters.group !== null && filters.group !== undefined) filterCriteria.push(new LHSStringFilter('group', LHSStringFilter.LHSOperator.like, filters.group))

            if (filterCriteria.length > 0) posDataClient.filterByLHSFilter(filterCriteria, mode)
        }

        /** @type {Pager} */
        const pager = await posDataClient.paginate(fromDate, toDate, Pager.RangeUnit.time);
        // switch(group) {}

        let entities = []
        pager.getEntities().forEach((entity) => {
            entities.push({...entity, id: name+"_"+entity.key, name: name})
        })

        pager.setEntities(entities)
        return pager
    }
}