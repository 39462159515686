import { config } from "../../../config"
import { 
    ApiKeyAuth,
    ApiClient 
} from ".."

import { Pager } from "./response/Pager";


export class AdvancedApiClient extends ApiClient {

    _config = null;

    _step = 10;

    /** @type {Pager} */
    _pager = null;

    constructor() {

        super();
        
        this._config =  config;

        this.setBaseUrl(this._config.dev ? "" : this._config.baseurl);
        this.setLang(this._config.defaultLanguage.code);

        this.setContentType('application/json')
            .setVersion(this.getAcceptApiVersion())

        const apiKey = this.getApiKey();

        if(apiKey !== undefined && apiKey !== null) {
            this.appendAuthSchema(new ApiKeyAuth(apiKey))
        } else {
            throw Error("ERROR: missing required ApiKey")
        }
    }

    setConfig(config) {
        this._config = config;
    }

    getConfig() {
        return this._config;
    }

    setPagingStep(step) {
        this._step = step
    }

    getPagingStep() {
        return this._step
    }

    getVersion() {
        return this._config.version;
    }

    getAcceptApiVersion() {
        return this._config.acceptApiVersion;
    }

    getApiKey() {
        return this._config.apiKey;
    }

    resetPaging() {
        this._pager = null;
    }

    filterById(id) {
        this.setRoute(this.getRoute() + "/{id}");
        return super.filterById(id);
    }

    async fetchPage(page, unit = Pager.RangeUnit.items) {
        const requestFirstPage = (this._pager === null || page === 0);
        if(requestFirstPage) {
            const from = page * this._step;
            const to = from + this._step;

            this._pager = await this.paginate(from, to, unit);
        } else {
            this._pager.setApiClient(this);
            this._pager = await this._pager.getNextPage();
        }
        return this._pager;
    }

}