import { AuthScheme } from "./AuthScheme";
// import { ApiRequest } from "../request/ApiRequest";

export class ApiKeyAuth extends AuthScheme {
    apiKey = null;

    constructor(apiKey, name = 'Api-Key', location = AuthScheme.Location.header) {
        super(name, location, AuthScheme.SchemeType.apiKey);
        this.apiKey = apiKey;
    }

    /**
     * 
     * @param {ApiRequest} request 
     * @returns {ApiRequest}
     */
     apply(request) {

        switch(this.location) {
            case AuthScheme.Location.header:
                request.setHeader(this.name, this.apiKey);
                // request.headers[this.name] = this.apiKey
                break;
            case AuthScheme.Location.query:
                request.setParam(this.name, this.apiKey);
                // request.params[this.name] = this.apiKey
                break;
            case AuthScheme.Location.cookie:
                throw new Error("Api-Key inside cookie not implemented");
            default:
                throw new Error("Api-Key location invalid");
        }

        return request;
    }
}