import {LHSFilter} from "./LHSFilter";

export class LHSIntFilter extends LHSFilter {

    isValidValues(values) {
        let result = false;

        if(Array.isArray(values)) {
            result = values.every((value) => {
                return (typeof value === 'number');
            });
        } else {
            result = (values === LHSFilter.NULL) || (typeof values === 'number');
        }

        return result;
    }

}