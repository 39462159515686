// import { config } from '../../config';

import { ApiClient, LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../lib/js-apiclient';
import { UserAnalyticsApiClient } from './UserAnalyticsApiClient';
import moment from 'moment-timezone';

export class UserAnalyticsModel {

    static TYPE_AVG_SCORE = "avg_score"
    static TYPE_TOT_SCORE = "tot_score"

    // static GROUP_DAILY = "day"
    static GROUP_WEEKLY = "week"
    static GROUP_MONTHLY = "month"
    static GROUP_YEARLY = "year"

    _token = null;
    _group = null;

    constructor(token, group) {
        this._token = (token !== null && token !== undefined) ? token : null
        this._group = group
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }
    
    async getUserData(term, sorting, filters) {
        const userDataClient = new UserAnalyticsApiClient(this._token, this._group);
        userDataClient.setPagingStep(10);

        if(term != null && term.length > 2) userDataClient.filterByString(term);

        if (sorting) {
            let orderCriteria = []
            
            if (sorting.first_name !== null) orderCriteria.push((new OrderCriteria('user.firstname', sorting.user_firstname).setWeight(120)))
            if (sorting.last_name !== null) orderCriteria.push((new OrderCriteria('user.lastname', sorting.user_lastname).setWeight(130)))
            if (sorting.email !== null) orderCriteria.push((new OrderCriteria('user.email', sorting.user_email).setWeight(140)))

            if (orderCriteria.length > 0) userDataClient.orderBy(orderCriteria)
        }
        
        if (filters) {
            let mode = ApiClient.FilterMode.AND
            let filterCriteria = [];

            if (filters.user_id !== null && filters.user_id !== undefined) filterCriteria.push(new LHSIntFilter('user_id', LHSIntFilter.LHSOperator.eq, filters.user_id))
            if (filters.from_date !== null && filters.from_date !== undefined) {
                let fromDate = moment(filters.from_date).set({hour:0, minute:0, second:0, millisecond:0})
                let from = moment.tz(fromDate.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss', 'UTC').format();
                
                filterCriteria.push(new LHSStringFilter('checked_at', LHSStringFilter.LHSOperator.gte, from))
            }
            if (filters.to_date !== null && filters.to_date !== undefined) {
                let toDate = moment(filters.to_date).set({hour:23, minute:59, second:59, millisecond:999})
                let to = moment.tz(toDate.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss', 'UTC').format();

                filterCriteria.push(new LHSStringFilter('checked_at', LHSStringFilter.LHSOperator.lte, to))
            }

            if (filterCriteria.length > 0) userDataClient.filterByLHSFilter(filterCriteria, mode)
        }

        return await userDataClient.findAll();
    }
}