// import { config } from '../../config';

import { LHSFilter, LHSIntFilter, LHSStringFilter, OrderCriteria } from '../../../lib/js-apiclient';
import { PosApiClient } from './PosApiClient';

export class PosModel {

    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }

    async getPosById(id) {
        const posClient = new PosApiClient(this._token, id);
        return posClient.findOne();
    }
    
    async getPosList(page, term, sorting, filters) {

        const posClient = new PosApiClient(this._token)
        posClient.setPagingStep(50)

        if(term != null && term.length > 2) posClient.filterByString(term)
        if (sorting) {
            let orderCriteria = []

            if (sorting.id !== null) orderCriteria.push((new OrderCriteria('id', sorting.id)).setWeight(10))
            if (sorting.code !== null) orderCriteria.push((new OrderCriteria('code', sorting.code)).setWeight(20))
            if (sorting.company_name !== null) orderCriteria.push((new OrderCriteria('company_name', sorting.company_name).setWeight(30)))
            if (sorting.sign !== null) orderCriteria.push((new OrderCriteria('sign', sorting.sign).setWeight(30)))
            if (sorting.group !== null) orderCriteria.push((new OrderCriteria('group', sorting.group).setWeight(40)))
            if (sorting.type !== null) orderCriteria.push((new OrderCriteria('type', sorting.type).setWeight(40)))
            // if (sorting.city !== null) orderCriteria.push((new OrderCriteria('city', sorting.city).setWeight(50)))
            // if (sorting.province !== null) orderCriteria.push((new OrderCriteria('province', sorting.province).setWeight(60)))
            if (sorting.address !== null) {
                orderCriteria.push((new OrderCriteria('address', sorting.address).setWeight(50)))
                orderCriteria.push((new OrderCriteria('city', sorting.city).setWeight(60)))
                orderCriteria.push((new OrderCriteria('province', sorting.province).setWeight(70)))
            }
            if (sorting.state !== null) orderCriteria.push((new OrderCriteria('state', sorting.state).setWeight(80)))
            if (sorting.avg_score !== null) orderCriteria.push((new OrderCriteria('avg_score', sorting.avg_score).setWeight(90)))
            if (sorting.num_checks !== null) orderCriteria.push((new OrderCriteria('num_checks', sorting.num_checks).setWeight(100)))
            if (sorting.last_survey_at !== null) orderCriteria.push((new OrderCriteria('last_survey_at', sorting.last_survey_at).setWeight(110)))
            
            if (sorting.user_firstname !== null) orderCriteria.push((new OrderCriteria('user.firstname', sorting.user_firstname).setWeight(120)))
            if (sorting.user_lastname !== null) orderCriteria.push((new OrderCriteria('user.lastname', sorting.user_lastname).setWeight(130)))
            if (sorting.user_email !== null) orderCriteria.push((new OrderCriteria('user.email', sorting.user_email).setWeight(140)))

            if (orderCriteria.length > 0) posClient.orderBy(orderCriteria)
        }

        // console.log("getPosList-code", filters)
        if(filters) {
            let lhsFilters = []
            if(filters.code !== null) lhsFilters.push(new LHSStringFilter('code', LHSFilter.LHSOperator.like, filters.code))
            if(filters.company_name !== null) lhsFilters.push(new LHSStringFilter('company_name', LHSFilter.LHSOperator.like, filters.company_name))
            if(filters.sign !== null) lhsFilters.push(new LHSStringFilter('sign', LHSFilter.LHSOperator.like, filters.sign))
            if(filters.group !== null) lhsFilters.push(new LHSStringFilter('group', LHSFilter.LHSOperator.like, filters.group))
            if(filters.type !== null) lhsFilters.push(new LHSStringFilter('type', LHSFilter.LHSOperator.like, filters.type))
            if(filters.address !== null) lhsFilters.push(new LHSStringFilter('address', LHSFilter.LHSOperator.like, filters.address))
            if(filters.cap !== null) lhsFilters.push(new LHSStringFilter('cap', LHSFilter.LHSOperator.like, filters.cap))
            if(filters.city !== null) lhsFilters.push(new LHSStringFilter('city', LHSFilter.LHSOperator.like, filters.city))
            if(filters.province !== null) lhsFilters.push(new LHSStringFilter('province', LHSFilter.LHSOperator.like, filters.province))
            if(filters.state !== null) lhsFilters.push(new LHSStringFilter('state', LHSFilter.LHSOperator.like, filters.state))
            if(filters.avg_score_min !== null) lhsFilters.push(new LHSIntFilter('avg_score', LHSFilter.LHSOperator.gte, filters.avg_score_min))
            if(filters.avg_score_max !== null) lhsFilters.push(new LHSIntFilter('avg_score', LHSFilter.LHSOperator.lte, filters.avg_score_max))
            if(filters.num_checks_min !== null) lhsFilters.push(new LHSIntFilter('num_checks', LHSFilter.LHSOperator.gte, filters.num_checks_min))
            if(filters.num_checks_max !== null) lhsFilters.push(new LHSIntFilter('num_checks', LHSFilter.LHSOperator.lte, filters.num_checks_max))
            if(filters.last_survey_at_min !== null) lhsFilters.push(new LHSStringFilter('last_survey_at', LHSFilter.LHSOperator.gte, filters.last_survey_at_min))
            if(filters.last_survey_at_max !== null) lhsFilters.push(new LHSStringFilter('last_survey_at', LHSFilter.LHSOperator.lte, filters.last_survey_at_max))
            if(filters.checked_at_min !== null) lhsFilters.push(new LHSStringFilter('checked_at', LHSFilter.LHSOperator.gte, filters.checked_at_min))
            if(filters.checked_at_max !== null) lhsFilters.push(new LHSStringFilter('checked_at', LHSFilter.LHSOperator.lte, filters.checked_at_max))
            if(filters.user_firstname !== null) lhsFilters.push(new LHSStringFilter('user[firstname]', LHSFilter.LHSOperator.like, filters.user_firstname))
            if(filters.user_lastname !== null) lhsFilters.push(new LHSStringFilter('user[lastname]', LHSFilter.LHSOperator.like, filters.user_lastname))
            if(filters.user_email !== null) lhsFilters.push(new LHSStringFilter('user[email]', LHSFilter.LHSOperator.like, filters.user_email))

            if (lhsFilters.length > 0) posClient.filterByLHSFilter(lhsFilters)
        }

        return await posClient.fetchPage(page)
    }

    async deletePosById(id) {
        const posClient = new PosApiClient(this._token, id)
        return posClient.remove()
    }

    async createPos(pos) {
        const posClient = new PosApiClient(this._token);
        let cleanPos = PosApiClient.cleanJson(pos, ['user_id'])

        delete cleanPos['id']
        delete cleanPos['user']
        delete cleanPos['area']
        delete cleanPos['avg_score']
        delete cleanPos['tot_score']

        return posClient.save(cleanPos);
    }

    // async _createPos(pos) {
    //     const posClient = new PosApiClient(this._token);

    //     return posClient.save({
    //         user_id: pos.user_id,
    //         code: pos.code,
    //         sign: pos.sign,
    //         group: pos.group,
    //         city: pos.city,
    //         province: pos.province,
    //         state: pos.state,
    //         cap: pos.cap,
    //         address: pos.address,
    //         address_number: pos.address_number,
    //         email: pos.email,
    //         phone: pos.phone,
    //         company_name: pos.company_name,
    //         owner_name: pos.owner_name,
    //         owner_phone: pos.owner_phone,
    //         owner_email: pos.owner_email,
    //         manager_name: pos.manager_name,
    //         manager_phone: pos.manager_phone,
    //         open_at: pos.open_at,
    //         shopping_center: pos.shopping_center,
    //         uncovered_parking: pos.uncovered_parking,
    //         covered_parking: pos.covered_parking,
    //         mq_sales_area: pos.mq_sales_area,
    //         mq_store: pos.mq_store,
    //         num_checkouts: pos.num_checkouts,
    //         num_scales: pos.num_scales,
    //         num_employees: pos.num_employees,
    //         location_value: pos.location_value,
    //         professional_level: pos.professional_level,
    //         dep_bakery: pos.dep_bakery,
    //         dep_canned: pos.dep_canned,
    //         dep_cleaning: pos.dep_cleaning,
    //         dep_dls: pos.dep_dls,
    //         dep_fish: pos.dep_fish,
    //         dep_fruit: pos.dep_fruit,
    //         dep_frozen: pos.dep_frozen,
    //         dep_frozen_bulk: pos.dep_frozen_bulk,
    //         dep_gastronomy: pos.dep_gastronomy,
    //         dep_meat: pos.dep_meat,
    //         dep_no_food: pos.dep_no_food,
    //         dep_news: pos.dep_news,
    //         dep_restaurant_area: pos.dep_restaurant_area,
    //         dep_wine: pos.dep_wine,
    //         ml_stand_sa_fo: pos.ml_stand_sa_fo,
    //         ml_stand_back: pos.ml_stand_back,
    //         ml_stand_pastry: pos.ml_stand_pastry,
    //         ml_stand_hot: pos.ml_stand_hot,
    //         ml_stand_bakery_served: pos.ml_stand_bakery_served,
    //         ml_stand_bakery_self: pos.ml_stand_bakery_self,
    //         ml_stand_fresh_tubs: pos.ml_stand_fresh_tubs,
    //         ml_stand_fresh_tubs: pos.ml_stand_fresh_tubs,
    //         ml_stand_fresh_tubs: pos.ml_stand_fresh_tubs,
    //         ml_stand_meat_takeaway: pos.ml_stand_meat_takeaway,
    //         ml_stand_meat_promo: pos.ml_stand_meat_promo,
    //         ml_stand_fruit_wall: pos.ml_stand_fruit_wall,
    //         mq_stand_fruit_square: pos.mq_stand_fruit_square,
    //         mt_stand_fruit_promo: pos.mt_stand_fruit_promo,
    //         ml_stand_dls: pos.ml_stand_dls,
    //         mt_stand_dls_developed: pos.mt_stand_dls_developed,
    //         mt_stand_dls_promo: pos.mt_stand_dls_promo,
    //         cells_fresh: pos.cells_fresh,
    //         cells_fresh: pos.cells_fresh,
    //         cells_fruit: pos.cells_fruit,
    //         cells_free: pos.cells_free,
    //         mt_stand_frozen_wall: pos.mt_stand_frozen_wall,
    //         num_stand_frozen_tubs: pos.num_stand_frozen_tubs,
    //         num_stand_frozen_bulk_tubs: pos.num_stand_frozen_bulk_tubs,
    //         mt_stand_fish_wall: pos.mt_stand_fish_wall,
    //         num_stand_fish_tubs: pos.num_stand_fish_tubs,
    //         mt_shelf: pos.mt_shelf,
    //         mt_shelf_133: pos.mt_shelf_133,
    //         mt_shelf_125: pos.mt_shelf_125,
    //         mt_shelf_120: pos.mt_shelf_120,
    //         mt_shelf_100: pos.mt_shelf_100,
    //         mt_shelf_75: pos.mt_shelf_75,
    //         mt_shelf_60: pos.mt_shelf_60,
    //         mt_shelf_50: pos.mt_shelf_50,
    //         mt_water: pos.mt_water,
    //         num_water_rack: pos.num_water_rack,
    //         num_loading_platform: pos.num_loading_platform,
    //         night_loading_enabled: pos.night_loading_enabled,
    //     });
    // }


    async updatePos(id, pos) {
        const posClient = new PosApiClient(this._token, id);
        let cleanPos = PosApiClient.cleanJson(pos, ['user_id'])

        delete cleanPos['user']
        delete cleanPos['area']
        delete cleanPos['avg_score']
        delete cleanPos['tot_score']

        // console.log(id, pos, cleanPos)

        return posClient.save(cleanPos)
    }
}