import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class ResponseMediaApiClient extends AdvancedApiClient {

    static entityType = "Media"

    constructor(token, surveyId, responseId, id) {
        super();

        this.setEntityType(ResponseMediaApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/survey/{sid}/response/{rid}/media/{id}")
                .setRouteParam("sid", surveyId)
                .setRouteParam("rid", responseId)
                .setRouteParam("id", id)
        } else {
            this.setRoute("/api/v1/survey/{sid}/response/{rid}/media")
                .setRouteParam("sid", surveyId)
                .setRouteParam("rid", responseId)
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}