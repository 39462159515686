import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { TemplateModel } from '../models/TemplateModel';


const templateAdapter = createEntityAdapter()

export const templateApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'template/fetchAll', 
        async ({areaId, term, sorting}, thunkAPI) => {
            try {
                const token = templateApi._getToken(thunkAPI);
                const templateModel = new TemplateModel(token, areaId);

                return await templateModel.getTemplatesList(term, sorting);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    create: createAsyncThunk(
        'template/create', 
        async ({areaId, template}, thunkAPI) => {
            const token = templateApi._getToken(thunkAPI);
            const templateModel = new TemplateModel(token, areaId);

            try {
                return await templateModel.createTemplate(template);
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    update: createAsyncThunk(
        'template/update', 
        async ({areaId, template}, thunkAPI) => {
            const token = templateApi._getToken(thunkAPI);
            const templateModel = new TemplateModel(token, areaId);
            
            try {
                return await templateModel.updateTemplate(template.id, template);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
    delete: createAsyncThunk( //scrivere tutti gli extra reducers
        'template/delete', 
        async ({areaId, template}, thunkAPI) => {
            const token = templateApi._getToken(thunkAPI);
            const templateModel = new TemplateModel(token, areaId);

            try {
                await templateModel.deleteTemplateById(template.id);
                return template;
            } catch (apiError) {
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const templateSlice = createSlice({
    name: "template",
    initialState: templateAdapter.getInitialState({
        status: 'idle',
        error: null,
        lastTemplateAdded: null,
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        clearTemplateList(state, action) {
            templateAdapter.removeAll(state);
        }
    },
    extraReducers: {
        [templateApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'template/fetchAll/succeeded'
            state.error = null;
            
            templateAdapter.setAll(state, action.payload)
        },
        [templateApi.fetchAll.rejected]: (state, action) => {
            state.status = 'template/fetchAll/rejected'
            state.error = action.payload
        },
        [templateApi.create.fulfilled]: (state, action) => {
            state.status = 'template/create/succeeded'
            state.error = null;
            templateAdapter.addOne(state, action.payload)
            state.lastTemplate = action.payload
        },
        [templateApi.create.pending]: (state, action) => {
            state.status = 'template/create/pending';
        },
        [templateApi.create.rejected]: (state, action) => {
            state.status = 'template/create/rejected';
            state.error = action.payload;
        },
        [templateApi.delete.pending]: (state, action) => {
            state.status = 'template/delete/pending';
        },
        [templateApi.delete.fulfilled]: (state, action) => {
            state.status = 'template/delete/succeeded'
            state.error = null;
            console.log('DELETE', action.payload)
            templateAdapter.removeOne(state, action.payload.id)
            state.lastTemplate = action.payload
        },
        [templateApi.delete.rejected]: (state, action) => {
            state.status = 'template/delete/rejected';
            state.error = action.payload;
        },
        [templateApi.update.fulfilled]: (state, action) => {
            state.status = 'template/update/succeeded'
            state.error = null;
            templateAdapter.upsertOne(state, action.payload);
            state.lastTemplate = action.payload
        },
        [templateApi.update.pending]: (state, action) => {
            state.status = 'template/update/pending'
        },
        [templateApi.update.rejected]: (state, action) => {
            state.status = 'template/update/rejected';
            state.error = action.payload;
        },
    }
});

// export const selectAllTemplateByAreaId = createSelector(
//     (state, _) => selectAllTemplates(state),
//     (_, areaId) => areaId, 
//     (templates, areaId) => {
//         return templates.filter(template => template.area_id === areaId)
//     }
// );

export const {
    selectById: selectTemplateById,
    selectAll: selectAllTemplates,
} = templateAdapter.getSelectors((state) => state.templateReducer)

export const { setTerm, clearTemplateList } = templateSlice.actions;

export default templateSlice.reducer;
