
import { createSlice } from '@reduxjs/toolkit'
// import {
//   LOGO_BG,
//   NAVBAR_BG,
//   SIDEBAR_BG,
//   THEME,
//   DIRECTION,
//   SIDEBAR_POSITION,
//   HEADER_POSITION,
//   LAYOUT,
//   SIDEBAR_TYPE,
// } from "../constants/";

const INIT_STATE = {
  activeDir: "ltr",
  activeThemeLayout: "vertical", //TODO: non utilizzato
  activeTheme: "light",
  activeSidebarType: "full",
  activeLogoBg: "skin6",
  activeNavbarBg: "skin6",
  activeSidebarBg: "skin5",
  activeSidebarPos: "fixed",
  activeHeaderPos: "fixed",
  activeLayout: "full",
};

const settingSlice = createSlice({
  name: "setting",
  initialState: INIT_STATE,
  reducers: {
    setLogoBg(state, action) {
      state.activeLogoBg = action.payload;
    },
    setNavbarBg(state, action) {
      state.activeNavbarBg = action.payload;
    },
    setSidebarBg(state, action) {
      state.activeSidebarBg = action.payload;
    },
    setTheme(state, action) {
      state.activeTheme = action.payload;
    },
    setDir(state, action) {
      state.activeDir = action.payload;
    },
    setSidebarPos(state, action) {
      state.activeSidebarPos = action.payload;
    },
    setHeaderPos(state, action) {
      state.activeHeaderPos = action.payload;
    },
    setLayout(state, action) {
      state.activeLayout = action.payload;
    },
    setSidebarType(state, action) {
      state.activeSidebarType = action.payload;
    },
  }
});

export const { setLogoBg, setNavbarBg, setSidebarBg, setTheme, setDir, setSidebarPos, setHeaderPos, setLayout, setSidebarType } = settingSlice.actions;

export default settingSlice.reducer;


// export default (state = INIT_STATE, action) => {
//   switch (action.type) {
//     case LOGO_BG:
//       console.log("XXX", action);
//       return {
//         ...state,
//         activeLogoBg: action.payload,
//       };
//     case NAVBAR_BG:
//       return {
//         ...state,
//         activeNavbarBg: action.payload,
//       };
//     case SIDEBAR_BG:
//       return {
//         ...state,
//         activeSidebarBg: action.payload,
//       };
//     case THEME:
//       return {
//         ...state,
//         activeTheme: action.payload,
//       };
//     case DIRECTION:
//       return {
//         ...state,
//         activeDir: action.payload,
//       };
//     case SIDEBAR_POSITION:
//       return {
//         ...state,
//         activeSidebarPos: action.payload,
//       };
//     case HEADER_POSITION:
//       return {
//         ...state,
//         activeHeaderPos: action.payload,
//       };
//     case LAYOUT:
//       return {
//         ...state,
//         activeLayout: action.payload,
//       };
//     case SIDEBAR_TYPE:
//       return {
//         ...state,
//         activeSidebarType: action.payload,
//       };
//     default:
//       return state;
//   }
// };
