import { 
    createSlice, 
    createAsyncThunk,  
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { SurveyModel } from '../models/SurveyModel';


const responseAdapter = createEntityAdapter()

export const responseApi = {
    _getToken: (thunkAPI) => {
        return thunkAPI.getState().identityReducer.token.signature;
    },
    fetchAll: createAsyncThunk(
        'response/fetchAll', 
        async ({surveyId}, thunkAPI) => {
            try {
                const token = responseApi._getToken(thunkAPI);
                const surveyModel = new SurveyModel(token);

                return await surveyModel.getSurveyResponse(surveyId);
            } catch (apiError) {
                console.error(apiError)
                return thunkAPI.rejectWithValue(apiError.toJson());
            }
        }
    ),
}

const responseSlice = createSlice({
    name: "response",
    initialState: responseAdapter.getInitialState({
        status: 'idle',
        error: null,
    }),
    reducers: {
        setTerm(state, action) {
            state.term = action.payload
        },
        setSorting(state, action) {
          state.sorting = action.payload;
        },
        setFilters(state, action) {
          state.filters = action.payload;
        },
        clearResponseList(state, action) {
            responseAdapter.removeAll(state);
        },
        setResponse(state, action) {
            // console.log(action.payload)
            responseAdapter.upsertOne(state, action.payload)
        }
    },
    extraReducers: {
        [responseApi.fetchAll.fulfilled]: (state, action) => {
            state.status = 'response/fetchAll/succeeded'
            state.error = null;
            
            // console.log(action.payload)
            responseAdapter.setAll(state, action.payload)
        },
        [responseApi.fetchAll.rejected]: (state, action) => {
            state.status = 'response/fetchAll/rejected'
            state.error = action.payload
        },
    }
});

export const {
    selectById: selectResponseById,
    selectAll: selectAllResponse,
} = responseAdapter.getSelectors((state) => state.responseReducer)

export const { setTerm, setSorting, setFilters, clearResponseList, setResponse } = responseSlice.actions;

export default responseSlice.reducer;
