import { JWTAuth } from "../../../lib/js-apiclient"
import { AdvancedApiClient } from "../../../lib/js-apiclient/src/AdvancedApiClient";

export class AdvancedSurveyApiClient extends AdvancedApiClient {

    static entityType = "Survey"

    constructor(token, posId, templateId, id) {
        super();

        this.setEntityType(AdvancedSurveyApiClient.entityType);

        if(id !== undefined && id !== null) {
            this.setRoute("/api/v1/pos/{pid}/template/{tid}/survey/{id}")
                .setRouteParam("pid", posId)
                .setRouteParam("tid", templateId)
                .setRouteParam("id", id)
        } else {
            this.setRoute("/api/v1/pos/{pid}/template/{tid}/survey")
            .setRouteParam("pid", posId)
            .setRouteParam("tid", templateId)
        }
        
        if(token !== undefined && token !== null) {
            this.appendAuthSchema(new JWTAuth(token))
        }
    }

}