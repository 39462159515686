export class ApiResponse {
    // id?: string | number;
    status = 0;
    headers = new Map();
    body = null;
    rel = "";

    /**
     * 
     * @param {Number} status 
     * @param {Map} headers 
     * @param {Object} body 
     */
    constructor(status, headers = new Map(), body = null) {
        this.status = status
        this.headers = headers
        this.body = body;
        if (body !== null) {
            this.rel = body.rel;
        }
    }
}