export class ApiRequest {

    static HTTPMethod = {
        GET: "GET",
        POST: "POST",
        PUT: "PUT",
        PATCH: "PATCH",
        DELETE: "DELETE",
    }
    
    method = ApiRequest.HTTPMethod.GET;

    route = null;
    headers = new Map();
    params = new Map();
    
    body = null;

    username = null;
    password = null;

    /**
     * 
     * @param {URL} route 
     * @param {String} method 
     * @param {Map} headers 
     * @param {Object} body 
     */

    constructor(route, method = ApiRequest.HTTPMethod.GET, headers = new Map(), params = new Map(), body = null) {
        this.route = route
        this.setMethod(method.toUpperCase());
        this.headers = headers;
        this.params = params;
        this.body = body
    }

    getRoute() {
        return this.route;
    }

    setRoute(route) {
        this.route = route;
        return this;
    }

    getMethod() {
        return this.method.toUpperCase();
    }

    setMethod(method) {
        switch(method) {
            case ApiRequest.HTTPMethod.GET:
            case ApiRequest.HTTPMethod.POST:
            case ApiRequest.HTTPMethod.PUT:
            case ApiRequest.HTTPMethod.PATCH:
            case ApiRequest.HTTPMethod.DELETE:
                this.method = method;
                break;
            default:
                throw new Error(`ERROR: Invalid Method ${method}`);
        }
    }

    getBody() {
        return this.body;
    }

    setBody(body) {
        this.body = body;
        return this;
    }

    setHeader(key, value) {
        this.headers.set(key, value);
    }

    removeHeader(key) {
        if (this.headers.has(key)) {
            this.headers.delete(key);
        }
        return this;
    }

    getHeader(key) {
        return this.headers.get(key);
    }

    getHeaders() {
        return this.headers;
    }

    setParam(key, value) {
        const parsedKey = key
            .replace(/%5B/g, '[')
            .replace(/%5D/g, ']')
            .replace(/%7C/g, '|')
            .replace(/%3A/g, ':');

        const parsedValue = value
            .replace(/%5B/g, '[')
            .replace(/%5D/g, ']')
            .replace(/%7C/g, '|')
            .replace(/%3A/g, ':');

        this.params.set(parsedKey, parsedValue);
        return this;
    }

    removeParam(key) {
        if (this.params.has(key)) {
            this.params.delete(key);
        }
        return this;
    }

    getParam(key) {
        return this.params.get(key);
    }

    getParams() {
        return this.params;
    }
}