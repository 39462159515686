import { AreaApiClient } from './AreaApiClient';

export class AreaModel {
    _token = null;

    constructor(token) {
        this._token = (token !== null && token !== undefined) ? token : null
    }

    setToken(token) {
        this._token = (token !== null && token !== undefined) ? token : null;
    }
    
    async getAreaList(term) {
        const areaClient = new AreaApiClient(this._token);
        if(term != null && term.length > 2) areaClient.filterByString(term);

        return await areaClient.findAll();
    }

    async deleteAreaById(id) {
        const areaClient = new AreaApiClient(this._token, id);
        return areaClient.remove();
    }

    async getAreaById(id) {
        const areaClient = new AreaApiClient(this._token, id);
        return await areaClient.findOne()
    }

    async createArea(area) {
        const areaClient = new AreaApiClient(this._token);
        return areaClient.save(area);
    }

    async updateArea(id, area) {
        const areaClient = new AreaApiClient(this._token, id);
        return areaClient.save(area);
    }
}